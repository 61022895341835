import React from "react";
import * as S from "./MainSider/MainSider.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const SupportInfo: React.FC = () => {
  return (
    <S.SiderLogoSecondaryDiv style={{ display: "block" }}>
      <h4 className="support-title">Queremos ayudarte:</h4>
      <h4 className="support-subtitle">
        {" "}
        Si experimentas algún problema técnico, escríbenos:
      </h4>
      <p className="support-email">
        {" "}
        <FontAwesomeIcon icon={regular("envelope")} /> ulises.medrano@designamx.com
      </p>
    </S.SiderLogoSecondaryDiv>
  );
};
