import { PageTitle } from "components/common/PageTitle/PageTitle";
import { WorkCenterNewForm } from "components/workcenters/WorkCenterNewForm";
import React from "react";

const WorkCenterNewPage: React.FC = () => {
    return (
        <>
            <PageTitle>{`Nuevo Plantel`}</PageTitle>
            <WorkCenterNewForm />
        </>
    )
}

export default WorkCenterNewPage;