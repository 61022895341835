import { httpApi } from "api/http.api";
import { Employee, LoginReportType } from "components/employees/types";
import { SearchProps } from "types";

const controller = `user`;

export const register = (employeePayload: Employee): Promise<{user: Employee, email_status: boolean}> =>
  httpApi
    .post<{user: Employee, email_status: boolean}>(`${controller}`, { ...employeePayload })
    .then(({ data }) => data);

export const update = (employeePayload: Employee): Promise<Employee> =>
httpApi
  .put<Employee>(`${controller}/${employeePayload._id}`, { ...employeePayload })
  .then(({ data }) => data);

export const list = (): Promise<Employee[]> =>
  httpApi.get<Employee[]>(`${controller}/list`).then(({ data }) => data);

export const loginReport = (): Promise<LoginReportType[]> =>
  httpApi.get<LoginReportType[]>(`${controller}/loginReport`).then(({ data }) => data);

export const remove = (employeePayload: Employee): Promise<Employee> =>
  httpApi
    .delete(`${controller}/${employeePayload._id}`)
    .then(({ data }) => data);

export const listfilter = (rowCount: number): Promise<Employee[]> =>
  httpApi
    .get<Employee[]>(`${controller}/list/${rowCount}`)
    .then(({ data }) => data);

export const search = ({ searchParam, searchValue }: SearchProps) =>
  httpApi
    .get<Employee[]>(`${controller}/search/${searchParam}/${searchValue}`)
    .then(({ data }) => data);
