import { Descriptions } from "antd";
import { Company, Representative } from "components/companies/types";
import { DescritpionProps } from "../types";
import { getStatusLights } from "components/companies/utils";
type AgreementCompanyProps = {
  detailCompany: Company;
  representative: Representative;
  legalRep?: Representative;
};
export const CompnayAgremnt = ({
  detailCompany,
  representative,
  legalRep
}: AgreementCompanyProps) => {
  return (
    <div>
      <Descriptions {...DescritpionProps} size='small' title='Empresa'>
        <Descriptions.Item key='name' label='Razón social'>
          {detailCompany.name}
        </Descriptions.Item>

        <Descriptions.Item key='representatives' label='Contacto'>
          { representative ? `${representative.name}` : `No hay contacto de la empresa`}
        </Descriptions.Item>
        { representative && (
          <Descriptions.Item key='repEmail' label='Correo electrónico'>
            {representative.email}
          </Descriptions.Item>
        )}
        { representative && (
          <Descriptions.Item key='position' label='Puesto'>
            {representative.position}
          </Descriptions.Item>
        )}
        <Descriptions.Item key='taxId' label='RFC'>
          {detailCompany.taxId}
        </Descriptions.Item>
        { legalRep && (
          <Descriptions.Item key='legalRepresentative' label='Representante legal'>
            {legalRep.name}
          </Descriptions.Item>
        )}
        <Descriptions.Item key='status' label='Estado'>
          <p style={{ color: getStatusLights(detailCompany.status) }}>
            {detailCompany.status}
          </p>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
