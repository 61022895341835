import { PageTitle } from "components/common/PageTitle/PageTitle";
import { StudentEdit } from "components/students/StudentEdit/StudentEdit";
import { useParams } from "react-router-dom";
import React from "react";

const StudentEditPage: React.FC = () => {
    const params = useParams();
    return (
        <>
            <PageTitle>{`Editar Alumno`}</PageTitle>
            <StudentEdit studentId={JSON.parse(JSON.stringify(params))}/>
        </>
    )
}

export default StudentEditPage;