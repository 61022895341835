import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { groupByRegion, list } from 'api/municipality.api';
import {
  MunicipalitiesByRegion,
  Municipality,
  initialValuesMunicipality,
} from 'components/municipality/types';

export interface municipalitySlice {
  municipalities: Municipality[];
  municipality: Municipality;
  status: 'empty' | 'fulfilled' | 'error' | 'updating';
  municipalitiesByRegion?: MunicipalitiesByRegion[];
  municipalitiesByRegionStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
}

const initialState: municipalitySlice = {
  municipalities: [],
  municipality: initialValuesMunicipality,
  status: 'empty',
  municipalitiesByRegionStatus: 'empty',
};

export const doList = createAsyncThunk('municipality', async () =>
  list().then((res) => res)
);

export const doGroupByRegion = createAsyncThunk(
  'municipality/groupByRegion',
  async () => groupByRegion().then((res) => res)
);

const municipalitySlice = createSlice({
  name: 'municipality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doList.fulfilled, (state, action) => {
      state.municipalities = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doGroupByRegion.fulfilled, (state, action) => {
      state.municipalitiesByRegionStatus = 'fulfilled';
      state.municipalitiesByRegion = action.payload;
    });
  },
});

export default municipalitySlice.reducer;
