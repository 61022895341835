import { useState, useEffect } from 'react';
import * as S from '../Student.styles';
import { useNavigate } from 'react-router-dom';
import {
    CertificateFiles,
    Student,
    academicStatus,
    geneders,
    gradesString,
    patternCurp,
} from '../types';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Form,
  Skeleton,
  Modal,
  Empty,
  Divider,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { doGetOne, doPatch } from "../../../store/slices/studentSlice";
import { notificationController } from "controllers/notificationController";
import { doList as WorkcenterSub } from "store/slices/workCenterSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { doGetByStudent } from "store/slices/agreementSlice";
import { OptionsSelect } from "types/optionsSelect";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { Agreement } from "components/Agreements/types";
import { AgreementsList } from 'components/dashboard/AgreementsList';
import { AWSFileUploader } from 'components/common/FileManager/AWSFileUploader';
import { ShowDocumentBtn } from 'components/common/Document/ShowDocumentBtn';
import { Document } from 'components/common/Document/Document';
import { escapeRegExp } from 'lodash';
import { IsAdultStudentCurp } from 'components/Agreements/utils/utils';
import { FileChangesType } from 'types';
import { SendCustomErrorMessage } from 'utils/Status&ErrorMessage';

type EditStudentProps = { studentId: { id: string } };
dayjs.extend(customParseFormat);

export const StudentEdit = ({ studentId }: EditStudentProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { TextArea } = Input;

  const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  const [valueSubSystem, setValueSubSystem] = useState("");
  const { student } = useAppSelector((state) => state.student);

    const [form] = Form.useForm();

    // Data for select
    const [options, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
    const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>(
        []
    );

    const [isLoading, setLoading] = useState(false);

    //check if adult
    const [isAdult, setIsAdult] = useState(false);
    const curpHook = Form.useWatch('curp', form);

    //check agreements when student change status
    const [showComments, setShowComments] = useState<boolean>(false);
    const [studentAgreements, setStudentAgreements] = useState<Agreement[]>();
    const [showAgreements, setShowAgreements] = useState(false);

    // certificate file
    const [showDocument, setShowDocument] = useState(false);
    const [pdfViewerId, setPdfViewerId] = useState('');
    const [certificateFiles, setCertificateFiles] =
      useState<CertificateFiles[]>([]);

    // track changes
    const [filesChanges, setfilesChanges] = useState<FileChangesType | null>(
        null
    );

    const toggle = () => {
        setShowAgreements(!showAgreements);
    };

    useEffect(() => {
        dispatch(doGetOne({ searchParam: 'id', searchValue: studentId.id }));
    }, [dispatch]);

    useEffect(() => {
        if (subsystemStateStatus !== 'fulfilled') {
            dispatch(SubsystemList());
        }
        const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
            label: `${subs.nameShort ?? ' - '} `,
            value: subs.nameShort!,
            key: subs._id!,
        }));
        setOptionsSubsystem(optionsRaw);
    }, [dispatch, subsystemStateStatus, subsystems]);

    useEffect(() => {
        if (student) {
            changeSubsystem(student.workcenter.subsystemShort);
            setValueSubSystem(student.workcenter.subsystemShort);
            form.setFieldValue('subsystem', student.workcenter.subsystemShort);
            form.setFieldValue('id', student._id);
            form.setFieldValue('workcenter', student.workcenter._id);
            setOptionWorkCenter([
                {
                    label: student.workcenter.name,
                    value: student.workcenter._id!,
                    key: student.workcenter._id!,
                },
            ]);
            form.setFieldValue('name', student.name ?? '');
            form.setFieldValue(
                'paternalLastname',
                student.paternalLastname ?? ''
            );
            form.setFieldValue(
                'maternalLastname',
                student.maternalLastname ?? ''
            );
            form.setFieldValue('email', student.email ?? '');
            form.setFieldValue('curp', student.curp ?? '');
            form.setFieldValue('phone', student.phone ?? '');
            form.setFieldValue('gender', student.gender ?? '');
            form.setFieldValue('career', student.career ?? '');
            form.setFieldValue('grade', student.grade ?? '');
            if (student.certificateFiles && student.certificateFiles.length > 0) {
              setCertificateFiles([...student.certificateFiles]);
            }

            if (student.birthDate) {
                const birthD = dayjs(student.birthDate);
                form.setFieldValue('birthDate', birthD ?? '');
            }
            if (student.completionDate) {
                const completionD = dayjs(student.completionDate);
                form.setFieldValue('completionDate', completionD ?? '');
            }
            form.setFieldValue('academicStatus', student.academicStatus ?? '');
            form.setFieldValue('tutor', student.tutor ?? '');
            form.setFieldValue('leaveMotive', student.leaveMotive ?? '');
        }
    }, [student]);

    useEffect(() => {
        if (
            student.academicStatus === 'Baja temporal' ||
            student.academicStatus === 'Baja definitiva'
        ) {
            setShowComments(true);
            form.setFieldValue('leaveMotive', student.leaveMotive ?? '');
        } else {
            setShowComments(false);
        }
        setShowAgreements(false);
    }, [student]);

    useEffect(() => {
        if (workcenterStateStatus !== 'fulfilled') {
            dispatch(WorkcenterSub());
        }
    }, [dispatch, workcenterStateStatus]);

    useEffect(() => {
        if (curpHook && curpHook !== '' && IsAdultStudentCurp(curpHook)) {
            setIsAdult(true);
        } else {
            setIsAdult(false);
        }
    }, [student, curpHook]);

    const filterWorkcenter = (workCenterName: string) => {
        const escapedWorkcName = escapeRegExp(workCenterName);
        const inputString = new RegExp(`${escapedWorkcName}`, 'gi');
        const newOptions = workcenters
            .filter((value) => inputString.test(value.name))
            .map((workcenter) => ({
                label: workcenter.name!,
                value: workcenter._id!,
                key: workcenter._id!,
            }));
        setOptionWorkCenter(newOptions);
    };

    const changeSubsystem = (subsystemSelected: string) => {
        const optionsRaw: OptionsSelect[] = workcenters
            .filter(
                (workcenter) => workcenter.subsystemShort === subsystemSelected
            )
            .map((workcenter) => ({
                label: workcenter.name!,
                value: workcenter._id!,
                key: workcenter._id!,
            }));
        setOptionWorkCenter(optionsRaw);
        setValueSubSystem(subsystemSelected);
    };

    const handleAcademicStatusChange = (academicStatus: string) => {
        if (
            academicStatus === 'Baja temporal' ||
            academicStatus === 'Baja definitiva'
        ) {
            setShowComments(true);
            return true;
        }
        setShowComments(false);
        return false;
    };

    const checkStudentNotIdleAgreements = async () => {
        const studentAgreements = await doGetByStudent(student._id ?? '');
        const filteredAgreements = studentAgreements.filter(
            (agreement) => agreement.status !== 'INACTIVO'
        );
        if (filteredAgreements.length > 0) {
            setShowAgreements(true);
            setStudentAgreements(filteredAgreements);
            return true;
        }
        setShowAgreements(false);
        return false;
    };

    const handleSubmit = async (values: Student) => {
        if (!academicStatus.includes(values.academicStatus)) {
            notificationController.error({
                message:
                    'Selecciona un estado académico válido (activo, baja temporal, baja definitva, egresado)',
            });
            return;
        }
        setLoading(true);
        let notIddleAgreementExist = false;
        if (
            values.academicStatus === 'Baja definitiva' ||
            values.academicStatus === 'Baja temporal'
        ) {
            notIddleAgreementExist = await checkStudentNotIdleAgreements();
        }
        if (notIddleAgreementExist === true) {
            values.leaveMotive = '';
            setLoading(false);
            return false;
        }
        let changedValues: Student = form.getFieldsValue(
            true,
            (meta) => meta.touched
        );
        changedValues = { ...changedValues, ...filesChanges };
        const sendPayload = {
            changedValues,
            student: values,
        };
        dispatch(doPatch(sendPayload))
            .unwrap()
            .then(() => {
                notificationController.success({
                    message: 'Alumno editado correctamente',
                });
                navigate('/alumnos');
            })
            .catch((err) => {
                if (err.message && err.message.includes('status code 427')) {
                    notificationController.error({
                        message: 'Error: Curp o correo duplicado',
                        description:
                            'Puede que el alumno ya se encuentre registrado en otro plantel, solicita a un administrador el cambio de plantel del estudiante.',
                    });
                } else {
                   SendCustomErrorMessage({ error: err });
                }
                setLoading(false);
            });
        setLoading(false);
        return true;
    };

    return (
        <>
            <S.Wrapper>
                {student && student.name !== '' ? (
                    <S.Card
                        id="students-new"
                        title={
                            <>
                                <CloseButton
                                    onClick={() => {
                                        navigate(STUDENTS_PATH);
                                    }}
                                ></CloseButton>
                                <span>&nbsp; {`Editar Alumno`}</span>
                            </>
                        }
                        padding="1.25rem"
                    >
                        <BaseForm
                            layout="vertical"
                            form={form}
                            onFinish={handleSubmit}
                            requiredMark="optional"
                        >
                            <BaseForm.Item name="id" hidden />
                            <Row>
                                <Col style={{ marginRight: 25 }}>
                                    <BaseForm.Item
                                        required={false}
                                        label={`Subsistema / IES`}
                                        name="subsystem"
                                    >
                                        <Select
                                            style={{ width: 200 }}
                                            value={valueSubSystem}
                                            placeholder="Sub-Sistema"
                                            options={optionsSubsystem}
                                        />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1}>
                                    <BaseForm.Item
                                        name="workcenter"
                                        label={`Elige el Plantel`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            options={options}
                                            onSearch={filterWorkcenter}
                                            onDropdownVisibleChange={() =>
                                                changeSubsystem(
                                                    form.getFieldValue(
                                                        'subsystem'
                                                    )
                                                )
                                            }
                                            optionLabelProp="label"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                        />
                                    </BaseForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex={1} style={{ marginRight: 20 }}>
                                    <BaseForm.Item
                                        name="name"
                                        label={`Nombre(s)`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingrese el nombre" />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1} style={{ marginRight: 20 }}>
                                    <BaseForm.Item
                                        name="paternalLastname"
                                        label={`Apellido paterno`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingrese el apellido" />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1} style={{ marginRight: 20 }}>
                                    <BaseForm.Item
                                        name="maternalLastname"
                                        label={`Apellido materno`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingrese el apellido" />
                                    </BaseForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="email"
                                        label={`Correo Electrónico`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                            {
                                                type: 'email',
                                                message:
                                                    'Por favor, ingresa un correo electrónico válido',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Correo Electronico" />
                                    </BaseForm.Item>
                                </Col>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="curp"
                                        label={`CURP`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                            {
                                                validator: (rule, value) => {
                                                    if (
                                                        value &&
                                                        patternCurp.test(value)
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Ingresa una CURP válida usando mayúsculas y números'
                                                    );
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="A-Z"
                                            showCount
                                            maxLength={18}
                                        />
                                    </BaseForm.Item>
                                </Col>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="phone"
                                        label={`Teléfono`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1}>
                                    <BaseForm.Item
                                        name="gender"
                                        label={`Género`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 200 }}
                                            options={geneders.map(
                                                (options) => ({
                                                    label: options,
                                                    value: options,
                                                    key: options,
                                                })
                                            )}
                                        />
                                    </BaseForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="career"
                                        label={`Carrera`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Escribe tu Carrera"
                                            max={60}
                                        />
                                    </BaseForm.Item>
                                </Col>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="grade"
                                        label={`Periodo escolar`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 150 }}
                                            options={gradesString.map(
                                                (grade) => ({
                                                    label: grade,
                                                    value: gradesString.indexOf(
                                                        grade
                                                    ),
                                                    key: grade,
                                                })
                                            )}
                                        />
                                    </BaseForm.Item>
                                </Col>
                                <Col style={{ marginRight: 20 }} flex={1}>
                                    <BaseForm.Item
                                        name="birthDate"
                                        label={'Fecha de nacimiento'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <DatePicker />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1}>
                                    <BaseForm.Item
                                        name="academicStatus"
                                        label={`Estado Académico`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 200 }}
                                            onChange={
                                                handleAcademicStatusChange
                                            }
                                            options={academicStatus.map(
                                                (status) => ({
                                                    label: status,
                                                    value: status,
                                                    key: status,
                                                })
                                            )}
                                        />
                                    </BaseForm.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex={1} style={{ marginRight: 20 }}>
                                    <BaseForm.Item
                                        name="tutor"
                                        // label={`Nombre(s) y Apellidos del Tutor`}
                                        rules={[
                                            {
                                                required: !isAdult,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Ingrese el nombre" />
                                    </BaseForm.Item>
                                </Col>
                                <Col flex={1}>
                                    <BaseForm.Item
                                        name="completionDate"
                                        label={'Fecha de Graduación'}
                                    >
                                        <DatePicker bordered />
                                    </BaseForm.Item>
                                </Col>
                            </Row>
                            {student.completedHours &&
                            student.completedHours.length > 0 &&
                            (student.completedHours.map(hoursObj => {
                              const certificate =
                                certificateFiles &&
                                certificateFiles.find(
                                  (certificate) =>
                                    certificate.educativeLevel ===
                                      hoursObj.educativeLevel
                                );
                            const label = `Certificado de finalización (${hoursObj.educativeLevel})`;
                            return hoursObj.completedHours &&
                              hoursObj.completedHours < 1000 ? (
                              <></>
                            ) : (
                              <Row>
                                <Col flex={1}>
                                  <BaseForm.Item label={label}>
                                    {certificate ? (
                                      <ShowDocumentBtn
                                        label='Abrir certificado'
                                        onClick={() => {
                                          setPdfViewerId(
                                            certificate.certificateFileId
                                          );
                                          setShowDocument(true);
                                        }}
                                      />
                                    ) : (
                                      <>No hay certificado de finalización </>
                                    )}
                                    <AWSFileUploader
                                      title={label}
                                      bucket='student-certificate'
                                      isReplacing={
                                        certificate?.certificateFileId
                                      }
                                      onUploadCompleted={(
                                        id: string | null
                                      ) => {
                                        const currentCertificates = [
                                          ...certificateFiles,
                                        ];
                                        const modifiedCertificate: CertificateFiles | null =
                                          id
                                            ? {
                                                educativeLevel:
                                                  hoursObj.educativeLevel,
                                                certificateFileId: id,
                                              }
                                            : null;
                                        const index =
                                          currentCertificates.findIndex(
                                            (certificate) =>
                                              certificate.certificateFileId ===
                                              hoursObj.educativeLevel
                                          );

                                        if (modifiedCertificate) {
                                          if (index !== -1) {
                                            currentCertificates[index] =
                                              modifiedCertificate;
                                          } else {
                                            currentCertificates.push(
                                              modifiedCertificate
                                            );
                                          }
                                        } else {
                                          currentCertificates.splice(index, 1);
                                        }
                                        setfilesChanges({
                                          ...filesChanges,
                                          certificateFiles: [
                                            ...currentCertificates,
                                          ],
                                        });
                                        setCertificateFiles(
                                          currentCertificates
                                        );
                                      }}
                                    />
                                  </BaseForm.Item>
                                </Col>
                              </Row>
                            );
                            })
                                )}
                            {showComments && (
                                <Row>
                                    <Col flex={1}>
                                        <BaseForm.Item
                                            name="leaveMotive"
                                            label={'Motivo de baja'}
                                            rules={[
                                                {
                                                    required: showComments,
                                                    message:
                                                        'Este campo es obligatorio',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </BaseForm.Item>
                                    </Col>
                                </Row>
                            )}
                            <BaseForm.Item noStyle>
                                <S.SubmitButton
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={isLoading}
                                >
                                    {`Registrar`}
                                </S.SubmitButton>
                            </BaseForm.Item>
                            <Document
                                fileId={pdfViewerId}
                                open={showDocument}
                                onCancel={() => setShowDocument(false)}
                                onOk={() => setShowDocument(false)}
                            />
                        </BaseForm>
                    </S.Card>
                ) : (
                    <Skeleton></Skeleton>
                )}
            </S.Wrapper>

            <Modal
                title={'No se puede dar de baja al alumno'}
                open={showAgreements}
                onOk={() => toggle()}
                onCancel={() => toggle()}
                destroyOnClose
            >
                <p>
                    No se puede dar de baja a un alumno con expedientes. Dar de
                    baja los expedientes antes de continuar.
                </p>
                <Divider orientation="left">Expedientes del alumno</Divider>
                {studentAgreements && studentAgreements.length > 0 ? (
                    <AgreementsList
                        agreements={studentAgreements}
                    ></AgreementsList>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                )}
            </Modal>
        </>
    );
};
