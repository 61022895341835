import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { WorkCenterListTable } from "components/workcenters/WorkCenterListTable";

const WorkCenterListPage: React.FC = () => {
    return (
        <>
            <PageTitle>{`Lista de Planteles`}</PageTitle>
            <WorkCenterListTable />
        </>
    )
}

export default WorkCenterListPage;