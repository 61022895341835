import { Card, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { filterProps } from 'components/common/FilterProps';
import { DownloadTableButton } from 'components/common/buttons/Button/DownloadTableButton';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { doListAgreementsByMunicipality } from 'store/slices/statisticsSlice';

export const MunicipalityAgreementsTable = () => {
  const [spin, setSpin] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    { municipality: string; agreement?: number }[]
  >([]);
  const {
    agreementsByMunicipality: agreements,
    agreementsByMunicipalityStatus: status,
  } = useAppSelector((state) => state.statistics);
  const dispatch = useAppDispatch();
  const dataColumns: ColumnsType<{ municipality: string; agreement?: number }> =
    [
      {
        title: 'Municipio',
        dataIndex: 'municipality',
        key: 'municipality',
        width: '50%',
        ...filterProps,
        onFilter: (value, record) => {
          if (!record.municipality) {
            return false;
          }
          return record.municipality
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        },
      },
      {
        title: 'Estudiantes',
        dataIndex: 'agreements',
        key: 'agreements',
        align: 'right',
      },
    ];

  useEffect(() => {
    if (status !== 'fulfilled') {
      dispatch(doListAgreementsByMunicipality());
    }
  }, [dispatch, agreements, status]);

  useEffect(() => {
    if (agreements && agreements.length > 0) {
      setSpin(true);
      const data = agreements.map((data) => {
        const { agreements, municipality } = data;
        return {
          municipality: municipality
            ? municipality
            : 'Estudiantes sin municipio asignado',
          agreements: agreements.length,
        };
      });
      const total = data.reduce((acc, current) => acc + current.agreements, 0);
      const tableData = data.sort((a, b) => b.agreements - a.agreements);
      tableData.push({ municipality: 'Total', agreements: total });
      setTableData(tableData);
      setSpin(false);
    }
  }, [agreements]);

  return (
    <Card
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        maxHeight: '45vh',
      }}
      title='Municipios con estudiantes duales'
      bordered={false}
      size='small'
      extra={<DownloadTableButton columns={dataColumns} data={tableData} />}
    >
      <Spin spinning={spin} delay={400} tip='Cargando...' size='large'>
        <Table
          scroll={{ x: true }}
          columns={dataColumns}
          dataSource={tableData}
          pagination={false}
          rowClassName={(record) =>
            record.municipality === 'Total' ? 'bold-row' : ''
          }
        />
      </Spin>
    </Card>
  );
};
