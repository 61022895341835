import { Row, Col, Card } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { seriesType } from './ColumnChart';
import { useEffect, useState } from 'react';
import { doList, doListInactive } from 'store/slices/agreementSlice';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Chart from 'react-apexcharts';

export const StudentGrowGraph = () => {
  const dispatch = useAppDispatch();
  const { agreements, status, inactiveAgreements, inactiveListStatus } =
    useAppSelector((state) => state.agreement);
  const [series, setSeries] = useState<seriesType>();
  const [categories, setCategories] = useState<string[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (status === 'fulfilled' && inactiveListStatus === 'fulfilled') {
      const agrArray = [...agreements, ...inactiveAgreements];
      let agrNoDate = 0;
      const dates = agrArray
        .map((agr) => {
          if (agr.status !== 'RECHAZADO')
            if (agr.startDate) {
              return new Date(agr.startDate);
            } else {
              agrNoDate++;
            }
        })
        .filter((date) => date !== undefined);
      const sortedDates = dates.sort((a, b) => {
        if (a! < b!) return -1;
        if (a! > b!) return 1;
        return 0;
      });
      const formatedDates = sortedDates.map((date) =>
        format(date!, 'MMMM-yyyy', { locale: es })
      );
      const datesCategories = Array.from(new Set(formatedDates));
      let counter = 0;
      const datesSeries: seriesType = { name: 'Estudiantes duales', data: [] };
      datesSeries.data = datesCategories.map((date, index) => {
        const students = formatedDates.reduce((acc, fDate) => {
          if (fDate === date) acc += 1;
          return acc;
        }, 0);
        counter +=
          index === datesCategories.length - 1
            ? students + agrNoDate
            : students;
        return counter;
      });
      setSeries(datesSeries);
      setCategories(datesCategories);
      setLoading(false);
    }
  }, [agreements, status, inactiveAgreements, inactiveListStatus]);
  useEffect(() => {
    if (status !== 'fulfilled') {
      dispatch(doList());
    }
  }, [dispatch, agreements, status]);

  useEffect(() => {
    if (inactiveListStatus !== 'fulfilled') {
      dispatch(doListInactive());
    }
  }, [dispatch, inactiveAgreements, inactiveListStatus]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title='Crecimiento acumulado de estudiantes duales'
          loading={loading}
          bordered={false}
          size='small'
        >
          {series && (
            <Chart
              type='bar'
              series={[series]}
              height={400}
              options={{
                chart: {
                  stacked: true,
                  toolbar: {
                    show: true,
                  },
                  zoom: {
                    enabled: true,
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                        height: 150,
                        legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0,
                        },
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                ],
                plotOptions: {
                  bar: {
                    horizontal: false,
                    dataLabels: {
                      hideOverflowingLabels: true,
                      orientation: 'vertical',
                      total: {
                        enabled: false,
                      },
                    },
                  },
                },
                xaxis: {
                  type: 'category',
                  categories: categories,
                  labels: {
                    formatter: function (label) {
                      const splitDate = label.split('-');
                      const newLabel = `${splitDate[0].substring(0, 3)}-${
                        splitDate[1]
                      }`;
                      return newLabel;
                    },
                  },
                },
                legend: {
                  position: 'right',
                  offsetY: 40,
                },
                fill: {
                  opacity: 1,
                },
              }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};
