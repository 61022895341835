import { Button, Form, Modal, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { notificationController } from "controllers/notificationController";
import { useAppDispatch } from "hooks/reduxHooks";
import { useState } from "react";
import { Company, Representative, UpdateStatusCompanyProps } from "./types";
import { doClear, doUpdateStatus as doUpdateStatusRep} from "store/slices/representativeSlice";
import { statusUpdate } from "api/company.api";
import { sendNotificationError } from "utils/Status&ErrorMessage";

type CommentsModalProps = {
  active: boolean;
  toggle: ()=>void;
  close?: (arg0: boolean)=>void;
  selectedRow: Company;
  rep?: Representative;
  statusCompany?: string;
};

export const CommentsModal = ({
  active,
  toggle,
  close,
  selectedRow,
  rep,
  statusCompany,
}: CommentsModalProps) => {
  const dispatch = useAppDispatch();
  // const [modalVisible, setModalVisible] = useState(active);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);

const handleOk = () => {

  if(valuesTextArea === ''){
    notificationController.error({
      message: "Debes indicar por qué haz marcado que faltan datos en el mensaje.",
  });
    return
  }

  setLoading(true);
  const new_status: UpdateStatusCompanyProps = {
    id: selectedRow._id ?? "",
    status: statusCompany,
    comment: rep
      ? "Representante " + rep?.name + " necesita correcciones: " + valuesTextArea
      : valuesTextArea,
  };
  if(rep) {
    dispatch(
        doUpdateStatusRep({
          id: rep._id?.toString() ?? "",
          status:"Rechazada",
        })
      )
  }
  statusUpdate(new_status)
    .then(() => {
      notificationController.success({
        message: 'Empresa Actualizada',
      });
      if (toggle) {
        toggle();
      }
      if (close) {
        close(false);
      }
    })
    .catch((error: unknown) => {
      sendNotificationError({ error });
      if (toggle) {
        toggle();
      }
      if (close) {
        close(false);
      }
    });
  setLoading(false);
  dispatch(doClear());
};
  const handleCancel = () => {
    if(toggle){
      toggle();
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValuesTextArea(e.target.value);
  };

  return (
    <>
      <Space>
        <Modal
          title='Ingresa tus comentarios'
          open={active}
          destroyOnClose
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type='primary' onClick={handleOk} loading={loading}>
              Enviar
            </Button>,
            <Button type='primary' danger onClick={handleCancel}>
              Cancelar
            </Button>,
          ]}
        >
          <Form layout='vertical' style={{ marginTop: 25 }} onFinish={handleOk}>
            <Form.Item>
              <TextArea
                size='small'
                allowClear
                required
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </>
  );
};
