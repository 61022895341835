import { Button, Col, Row } from "antd";
import { CheckAccess } from "checkAccess/CheckAccess";
import { EMPLOYEES_PATH } from "components/router/AppRouter";
import SkeletonButton from "antd/es/skeleton/Button";
import { ExportEmployees } from "./ExportEmployees";
import React from "react";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";

export const EmployeesHeaderBtns = () => {
  const { writeEmployees, exportAgreements } = PermissionsToAccess;
  const navigate = useNavigate();
  const { status: empStatus } = useAppSelector((state) => state.employee);
  return (
    <Row gutter={12}>
      {CheckAccess(writeEmployees) && [
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate(`${EMPLOYEES_PATH}/nuevo`);
            }}
          >
            Nuevo Colaborador
          </Button>
          ,
        </Col>,
      ]}
      {CheckAccess(exportAgreements) &&
        (empStatus === "fulfilled" ? (
          <Col>
            <ExportEmployees />
          </Col>
        ) : (
          <Col>
            <SkeletonButton block={true} />
          </Col>
        ))}
    </Row>
  );
};
