import { useEffect, useState } from 'react';
import { useAppSelector } from "hooks/reduxHooks";
import { CheckTourCompletion } from "utils/tourChecker";
import { Tourmodules } from 'components/employees/types';

export const useTourCheck = (module: (typeof Tourmodules)[number]) => {
  const { user } = useAppSelector((state) => state.user);
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false)

  useEffect(() => {
    if (!CheckTourCompletion(module, user!)) {
      setOpenTour(true);
      setIsFirstTime(true);
    } else {
      setIsFirstTime(false);
    }
  }, [module, user, isFirstTime]);

  return { setOpenTour, openTour, isFirstTime };
};