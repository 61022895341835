import { notificationController } from 'controllers/notificationController';
import * as Sentry from '@sentry/react';
import { ApiError } from 'api/ApiError';

export const ExtractStatusCode = (errorMessage: string) => {
    const match = errorMessage.match(/status code (\d+)/i);
    if (match && match[1]) {
        return parseInt(match[1], 10);
    }
    return undefined;
};

export const StatusCodeMessage = (statusCode: number) => {
    let message: string;
    switch (statusCode) {
        case 404:
            message = 'No se ha encontrado la información solicitada en la base de datos';
            break;

        case 420:
            message = 'Hay campos requeridos que no han sido llenados';
            break;

        case 421:
            message = 'La fecha ingresada no es válida';
            break;

        case 422:
            message = 'El formato es incorrecto';
            break;

        case 427:
            message = '¡Llave única duplicada! Verifica que los datos no se repitan en otro registro';
            break;

        case 443:
            message = 'La clave ingresada no es válida';
            break;

        case 446:
            message = 'Las horas indicadas no son válidas';
            break;

        default:
            message =
                'Estamos teniendo problemas para procesar tu solicitud, por favor, intenta más tarde';
            break;
    }
    return message;
};

type SendNotificationError = {
    error: Error;
    useSentry?: boolean;
}

export const sendNotificationError = ({
  error,
  useSentry = true,
}: SendNotificationError) => {
    if(error instanceof ApiError){
        let description = 'Ha habido un error al procesar tu petición';
        let status = 'Error';
        if(error.options.errorMessage){
            description = error.options.errorMessage;
        }
        if(error.options.status){
            status = `Error: ${error.options.status}`;
        }
        notificationController.error({
          message: status,
          description,
        });
        if(useSentry){
          Sentry.captureMessage(error.message);
        }
    } else {
        notificationController.error({
          message: 'Error',
          description: error.message,
        });
        if(useSentry){
          Sentry.captureMessage(error.message);
        }
    }
};

export const SendCustomErrorMessage = ({
    error,
    useSentry = true,
}: SendNotificationError) => {
    if (!error.message) {
        notificationController.error({
            message: 'Error',
        });
        if (useSentry) Sentry.captureMessage(`ERROR: ${error}`);
    }

    if (error.message.toLowerCase().includes('network error')) {
        notificationController.error({
            message:
                'Fallo al descargar el archivo, verifica tu conexión a internet',
        });
    } else {
        const statusCode = ExtractStatusCode(error.message);
        const message = StatusCodeMessage(statusCode as number);
        notificationController.error({
            message,
        });
        if (useSentry && statusCode !== 404 && statusCode !== 401)
            Sentry.captureMessage(
                `${error.message}, status code: ${statusCode}`
            );
    }
};
