import { PageTitle } from "components/common/PageTitle/PageTitle";
import { DataDisplay } from "components/dashboard/DataDisplay";
import React from "react";

const DashboardPage: React.FC = () => {
  return (
    <>
      <PageTitle>{`Dashboard`}</PageTitle>
      <DataDisplay />
    </>
  );
};

export default DashboardPage;
