import { WithChildrenProps } from 'types';
import React from 'react';
import * as S from './MainHeader.styles';

interface MainHeaderProps extends WithChildrenProps {
  isTwoColumnsLayout: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ isTwoColumnsLayout, children }) => {
  return <S.Header className="main-header" $isTwoColumnsLayoutHeader={isTwoColumnsLayout}>{children} </S.Header>;
};
