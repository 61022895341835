import { httpApi } from "api/http.api";
import { ApprovementRoleType } from "components/employees/types"

const controller = `config`;


export const agreementsApprovalProcess = (): Promise<ApprovementRoleType[]> =>
  httpApi
    .get<ApprovementRoleType[]>(`${controller}/agreementsApproval`)
    .then(({ data }) => data);

export const fileStorageConfig = (): Promise<string> =>
httpApi
  .get<string>(`${controller}/fileStorageConfig`)
  .then(({ data }) => data);
