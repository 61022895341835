import userReducer from "store/slices/userSlice";
import authReducer from "store/slices/authSlice";
import themeReducer from "store/slices/themeSlice";
import employeeReducer from "store/slices/employeeSlice";
import studentReducer from "store/slices/studentSlice";
import workcenterReducer from "store/slices/workCenterSlice";
import companyReducer from "store/slices/companySlice";
import agreementReducer from "store/slices/agreementSlice";
import statisticsReducer from "./statisticsSlice";
import representativeSlice from "./representativeSlice";
import configSlice from "./configSlice";
import subsystemSlice from "./subsystemSlice";
import municipalitySlice from "./municipalitySlice";

const store = {
  user: userReducer,
  auth: authReducer,
  theme: themeReducer,
  employee: employeeReducer,
  workcenter: workcenterReducer,
  student: studentReducer,
  company: companyReducer,
  agreement: agreementReducer,
  statistics: statisticsReducer,
  representative: representativeSlice,
  config: configSlice,
  susbsystem: subsystemSlice,
  municipality: municipalitySlice
};

export default store;
