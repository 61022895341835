import React from 'react';
import { NewPasswordForm } from 'components/auth/NewPasswordForm/NewPasswordForm';
import { PageTitle } from 'components/common/PageTitle/PageTitle';

const NewPasswordPage: React.FC = () => {

  return (
    <>
      <PageTitle>{`Nueva contraseña`}</PageTitle>
      <NewPasswordForm />
    </>
  );
};

export default NewPasswordPage;
