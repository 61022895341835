import React, { useEffect } from 'react';
import { NewPasswordForm } from 'components/auth/NewPasswordForm/NewPasswordForm';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { persistToken } from 'services/localStorage.service';
import { Alert } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doValidateConfirmToken } from 'store/slices/authSlice';

const NewPasswordPage: React.FC = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  useEffect(()=>{
    if (token) {
      persistToken(token)
      dispatch(doValidateConfirmToken())
    }
  },[token, dispatch]);
  return (
    <>
      { (token && user) ? (
          <>
            <PageTitle>{`Nueva contraseña`}</PageTitle>
            <NewPasswordForm />
          </>
        ) : ( <Alert message="Validando" type="info" /> )
      }
    </>
  );
};

export default NewPasswordPage;
