export type EducativeLevel = 'MEDIA SUPERIOR' | 'SUPERIOR';
export const educativeLevel = ['MEDIA SUPERIOR', 'SUPERIOR'];

export const educativeLevelOptions = educativeLevel.map((lvl) => ({
  label: lvl,
  value: lvl,
  key: lvl,
}));

export type Subsystem = {
  _id?: string;
  name: string;
  nameShort: string;
  principal: string;
  educativeLevel: EducativeLevel;
};

export const initialValues: Subsystem = {
  name: '',
  nameShort: '',
  principal: '',
  educativeLevel: 'MEDIA SUPERIOR',
};
