import { useAppSelector } from "hooks/reduxHooks";
import React from "react";
import { CSVLink } from "react-csv";

type CsvEmployeesProps = {
  csvLinkRef: React.RefObject<
    CSVLink &
      HTMLAnchorElement & {
        link: HTMLAnchorElement;
      }
  >;
};
export const CsvEmployees = ({csvLinkRef}: CsvEmployeesProps) => {
  const { employees } = useAppSelector((state) => state.employee);
  const getPermissionLevel = (level: number, isAdmin: boolean) => {
    if (isAdmin) {
      return "lectura y escritura";
    }
    switch (level) {
      case 0:
        return "sin acceso";
      case 1:
        return "solo lectura";
      case 2:
        return "lectura y escritura";
      default:
        return "";
    }
  };

  const headers = [
    { label: "Nombre", key: "name" },
    { label: "Correo electrónico", key: "email" },
    { label: "Teléfono", key: "phone" },
    { label: "Subsistema", key: "subsystem" },
    { label: "Plantel", key: "workcenter.name" },
    { label: "Puesto", key: "position" },
    { label: "Tipo de usuario", key: "role" },
    { label: "Nivel de aprobación de expedientes", key: "approvementRole" },
    { label: "Permisos en modulo Usuarios", key: "permissionsUser" },
    { label: "Permisos en modulo Expedientes", key: "permissionsAgreement" },
    { label: "Permisos en modulo Alumnos", key: "permissionsStudents" },
    { label: "Permisos en modulo Empresas", key: "permissionsCompany" },
    { label: "Permisos en modulo Planteles", key: "permissionsWorkcenter" },
    { label: "Permisos en modulo subsistemas", key: "permissionsSubsystem" },
    { label: "Permisos de exportación", key: "permissionsExport" },
  ];
  return (
    <CSVLink
      data={employees.map((emp) => {
        const isAdmin = emp.role && emp.role >= 1 ? true : false;
        return {
          ...emp,
          approvementRole:
            emp.approvementRole?.name || "No puede aprobar expedientes",
          role: isAdmin ? "Administrador" : "Usuario",
          permissionsUser: getPermissionLevel(
            emp.permissions ? emp.permissions[0].permission : 0,
            isAdmin
          ),
          permissionsAgreement: getPermissionLevel(
            emp.permissions ? emp.permissions[1].permission : 0,
            isAdmin
          ),
          permissionsStudents: getPermissionLevel(
            emp.permissions ? emp.permissions[2].permission : 0,
            isAdmin
          ),
          permissionsCompany: getPermissionLevel(
            emp.permissions ? emp.permissions[3].permission : 0,
            isAdmin
          ),
          permissionsWorkcenter: getPermissionLevel(
            emp.permissions ? emp.permissions[4].permission : 0,
            isAdmin
          ),
          permissionsSubsystem: getPermissionLevel(
            emp.permissions ? emp.permissions[5].permission : 0,
            isAdmin
          ),
          permissionsExport:
            isAdmin || (emp.permissions && emp.permissions[6].permission >= 2)
              ? "Puede exportar"
              : "No puede exportar",
        };
      })}
      headers={headers}
      className="exportButton"
      filename="colaboradores.csv"
      ref={csvLinkRef}
    ></CSVLink>
  );
};
