import {
  MunicipalitiesByRegion,
  Municipality,
} from 'components/municipality/types';
import { httpApi } from './http.api';

const controller = `municipality`;

export const list = (): Promise<Municipality[]> =>
  httpApi.get<Municipality[]>(`${controller}`).then(({ data }) => data);

export const groupByRegion = (): Promise<MunicipalitiesByRegion[]> =>
  httpApi
    .get<MunicipalitiesByRegion[]>(`${controller}/groupByRegion`)
    .then(({ data }) => data);
