import React from "react";
import { CheckAccess } from "../../checkAccess/CheckAccess";
import { PermissionsType } from "components/employees/types";
import { Navigate } from "react-router-dom";
import { NOACCESS_PATH } from "components/router/AppRouter";

interface RequirePermissionProps {
  children: React.ReactNode;
  accRequest: PermissionsType;
}

export const RequirePermission: React.FC<RequirePermissionProps> = ({
  children,
  accRequest
}) => {
  const isAuthorized = CheckAccess(accRequest)

  if (!isAuthorized) {
    return (
      <>
      <Navigate to={NOACCESS_PATH}/>;
      </>
    );
  }
  return <>{children}</>;
};
