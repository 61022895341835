import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { AgreementsListTable } from "components/Agreements/list/AgreementsListTable";

type AgreementListPageProps = {
    filter?: string
  };

const AgreementsListPage: React.FC<AgreementListPageProps> = ({filter}:AgreementListPageProps) => {
    return (
        <>
            <PageTitle>{`Lista de Expedientes`}</PageTitle>
            <AgreementsListTable filter={filter ?? ""}/>
        </>
    )
}

export default AgreementsListPage;