import { httpApi } from "api/http.api";
import { Representative, UpdateStatusCompanyProps } from "./../components/companies/types";
const controller = `representative`;

export type UpdateObject = {
  changedValues: Representative,
  representative: Representative,
}

export const create = (repPayload: Representative): Promise<Representative> =>
  httpApi
    .post<Representative>(`${controller}`, { ...repPayload })
    .then(({ data }) => data);

export const list = (): Promise<Representative[]> =>
  httpApi.get<Representative[]>(`${controller}`).then(({ data }) => data);

export const update = (repPayload: UpdateObject): Promise<Representative> =>
  httpApi
    .put<Representative>(`${controller}`, { ...repPayload })
    .then(({ data }) => data);

export const statusUpdate = (
  status: UpdateStatusCompanyProps
): Promise<Representative> =>
  httpApi
    .patch<Representative>(`${controller}/status`, { ...status })
    .then(({ data }) => data);

export const remove = (repPayload: Representative): Promise<Representative> =>
  httpApi.delete(`${controller}/${repPayload._id}`).then(({ data }) => data);
