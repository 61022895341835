import { format } from 'date-fns';

interface DataObject {
  [key: string]: unknown;
}

export const formatDate = (date: Date | string | undefined) =>
  date ? format(date, 'yyyy-MM-dd') : 'Sin registro';
export const setYesOrNo = (data: string | undefined) =>
  data && data !== '' ? 'Sí' : 'No';

export const formatDatesInObj = (obj: DataObject): DataObject => {
    const newObj: DataObject = {};
    for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        newObj[key] = formatDatesInObj(obj[key] as DataObject);
      } else if (
        typeof obj[key] === 'string' &&
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(obj[key] as string)
      ) {
        newObj[key] = format(obj[key] as string, 'yyyy-MM-dd');
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };