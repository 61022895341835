/*eslint @typescript-eslint/no-explicit-any: "off"*/
import { SelectProps } from 'antd';
import {
  Company,
  initialValues as initCompany,
  Representative,
} from 'components/companies/types';
import { initialValuesRep } from '../companies/types';

import {
  Student,
  initialValues as initStudent,
} from 'components/students/types';
import {
  initialValues as initWorkcenter,
  WorkCenter,
} from 'components/workcenters/types';
import {
  ApprovementRoleType,
  Employee,
  initialValues as initEmployee,
} from 'components/employees/types';

export type Agreement = {
  _id?: string;
  studentId: Student;
  student?: Student;
  workCenterId: WorkCenter;
  companyId: Company;
  representativeId: Representative;
  legalRepresentativeId?: Representative;
  startDate: Date | string;
  endDate: Date | string;
  inactiveDate?: Date | string;
  completedHours?: number;
  hoursPerWeek?: number;
  status: (typeof statusAgreement)[number];
  comments?: CommentsType[];
  sequence: number | '';
  learningAgreementFileId?: string;
  colaborationAgreementFileId?: string;
  tutorAuthLetterFileId?: string;
  tutorIdFileId?: string;
  insuranceFileId?: string;
  rotationPlanFileId?: string;
  learningPositionsFieldId?: string;
  matrixFieldId?: string;
  principalFileId?: string;
  laptopAssignmentFileId?: string;
  inactiveLetterFileId?: string;
  laptopSN?: string;
  laptopDeliveryDate?: Date | string;
  principal: Employee;
  principalPosition?: string;
  rotationTimes: number;
  rotationPositions?: string;
  approvalStep?: ApprovementRoleType;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  minimumRequiredFieldsPercent?: number;
  educativeLevel?: string;
  municipality?: string;
};
export type CommentsType = { body: string; by: string; createdAt?: Date };
export const initialValues: Agreement = {
  studentId: initStudent,
  workCenterId: initWorkcenter,
  companyId: initCompany,
  representativeId: initialValuesRep,
  principal: initEmployee,
  startDate: '',
  endDate: '',
  status: '',
  sequence: '',
  rotationTimes: 0,
  rotationPositions: '',
};

export type UpdateStatusProps = {
  id: string;
  comment: string;
  status?: (typeof statusAgreement)[number];
  approvalStep?: ApprovementRoleType;
  currentStatus?: (typeof statusAgreement)[number];
  inactiveDate?: Date | string;
};
export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export type AgreementsByMunicipality = {
  municipality: string;
  agreements: Agreement[];
};

export const statusAgreement = [
  'ACTIVO',
  'INACTIVO',
  'PENDIENTE DE REVISION',
  'BORRADOR',
  'RECHAZADO',
];

export const statusAgrementDict = {
  ACTIVO: 'Activo',
  INACTIVO: 'Inactivo',
  'PENDIENTE DE REVISION': 'Pendiente de revisión',
  BORRADOR: 'Incompleto',
  RECHAZADO: 'Necesita correcciones',
};
export const DescritpionProps = {
  bordered: true,
  column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
};
export const dateFormat = 'YYYY/MM/DD';
