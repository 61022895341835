import React from "react";
import { Col, Row } from "antd";
import * as S from "../Header.styles";

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  toggleSider,
  isSiderOpened,
}) => {
  return (
    <Row gutter={[16,16]} justify="space-between" align="middle">
      <Col offset={22} style={{ marginTop: "20px" }}>
        <S.BurgerCol>
          <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
        </S.BurgerCol>
      </Col>
    </Row>
  );
};
