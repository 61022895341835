import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { WithChildrenProps } from 'types';
import { setSentryUser } from 'store/slices/userSlice';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isSentryUserSet, user } = useAppSelector((state)=> state.user);
  const token = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if(!isSentryUserSet && user) {
      dispatch(setSentryUser(user))
    }
  }, [isSentryUserSet, user, dispatch])

  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;