import { useAppDispatch } from "hooks/reduxHooks";
import { useEffect } from "react";
import { doLogout } from "store/slices/authSlice";
import LoginPage from './LoginPage';
const LogOut = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(doLogout());
  }, [dispatch]);

  return <LoginPage></LoginPage>;
};

export default LogOut;
