import { httpApi } from "api/http.api";
import { SearchParams } from "components/companies/types";
import { WorkCenter } from "components/workcenters/types";

const controller = `workcenter`;

export const register = (workcenterPayload: WorkCenter): Promise<WorkCenter> =>
  httpApi
    .post<WorkCenter>(`${controller}`, { ...workcenterPayload })
    .then(({ data }) => data);

export const list = (): Promise<WorkCenter[]> =>
  httpApi.get<WorkCenter[]>(`${controller}`).then(({ data }) => data);

export const remove = (workcenterPayload: WorkCenter): Promise<WorkCenter> =>
  httpApi
    .delete(`${controller}`, { data: { id: workcenterPayload._id } })
    .then(({ data }) => data);

export const search = ({ searchParam, searchValue }: SearchParams) =>
  httpApi
    .get<WorkCenter[]>(`${controller}/search/${searchParam}/${searchValue}`)
    .then(({ data }) => data);

export const searchBySubSystem = ({ searchParam, searchValue }: SearchParams) =>
  httpApi
    .get<WorkCenter[]>(`${controller}/${searchParam}/${searchValue}`)
    .then(({ data }) => data);

export const listfilter = (rowCount: number): Promise<WorkCenter[]> =>
  httpApi
    .get<WorkCenter[]>(`${controller}/${rowCount}`)
    .then(({ data }) => data);

export const update = (workcenterPayload: WorkCenter): Promise<WorkCenter> =>
httpApi
  .put<WorkCenter>(`${controller}/${workcenterPayload._id}`, { ...workcenterPayload })
  .then(({ data }) => data);
