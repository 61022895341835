import { Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space } from '../../inputs/SearchInput/SearchInput.styles';

type CloseButtonProprs = {
    onClick: () => void
  };

  export const CloseButton = ({ onClick }: CloseButtonProprs) => (
    <Space>
      <Button shape="circle" title="Regresar" onClick={onClick}>
        <ArrowLeftOutlined />
      </Button>
    </Space>
  );