import { httpApi } from 'api/http.api';
import { User } from 'types';

const controller = `auth`

export interface AuthData {
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: User;
}

export interface RetrievePassResponse {
  user: User;
  email_status: boolean;
}

export const me = (): Promise<LoginResponse> => 
  httpApi.post<LoginResponse>(`${controller}/me`, {}).then(({ data }) => data);

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>(`${controller}/signin`, { ...loginPayload }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<RetrievePassResponse> =>
  httpApi.post<RetrievePassResponse>(`${controller}/forgotPassword`, { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>(`${controller}/verifySecurityCode`, { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>(`${controller}/setpassword`, { ...newPasswordData }).then(({ data }) => data);
