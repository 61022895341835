import React from "react";
import * as S from "./MainSider/MainSider.styles";
import { useResponsive } from "hooks/useResponsive";

export const SiderLogoSecodary: React.FC = () => {
  const { tabletOnly, mobileOnly, isDesktop, isBigScreen } = useResponsive();
  return (
    <S.SiderLogoSecondaryDiv className="sider-logo-sec-container">
     { (isBigScreen || isDesktop) &&  (
      <img src="/logoSecondary.svg" alt="Logo" className="sider-logo-secondary" />
     )}
      {(tabletOnly || mobileOnly) && (
         <img src="/logoSecondary.svg" alt="Logo" className="sider-logo-secondary-mobile" />
      )}
    </S.SiderLogoSecondaryDiv>
  );
};
