import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "components/router/AppRouter";

export const NoAccess: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Acceso no autorizado"
      extra={
        <Button type="primary" onClick={() => navigate(DASHBOARD_PATH)}>
          Volver a inicio
        </Button>
      }
    />
  );
};
