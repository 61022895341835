import axios from "axios";
import { AxiosError } from "axios";
import { ApiError } from "api/ApiError";
import { readToken } from "services/localStorage.service";
import { ExtractStatusCode } from "utils/Status&ErrorMessage";

export type CustomAxiosError = {
response: {data: {message?: string}, status?: number}
} & AxiosError

export interface ApiErrorData {
  status?: number;
  errorMessage?: string;
}

export const httpApi = axios.create({
  baseURL: "/api/",
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError | Error) => {

const errorHandling = (statusCode: number | undefined, defaultError: unknown) => {
  switch (statusCode) {
    case 401: {
      const alink = document.createElement('a');
      alink.href = '/auth/logout';
      alink.click();
      break;
    }

    default:
      throw defaultError;
  }
};

if (axios.isAxiosError(error)) {
  const { response } = error as CustomAxiosError;
  const axiosStatusCode = error.response?.status;
  const defaultError = new ApiError<ApiErrorData>(error.message, {
    errorMessage: response.data.message ?? '',
    status: response.status,
  });
  errorHandling(axiosStatusCode, defaultError);
}

const statusCode = ExtractStatusCode(error.message);
if (statusCode !== undefined) {
  const defaultError = new ApiError<ApiErrorData>(
    `ERROR: ${error.message}. Status code: ${statusCode}`
  );
  errorHandling(statusCode, defaultError);
}

throw new ApiError<ApiErrorData>(error.message);
});
