import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  companiesStatistics,
  CompanyStatistics,
  subsystemWorkCStatistics,
  SubWorkCStatistics,
  workCStatistics,
  agreementsByEducativeLevel,
  AgreementsByEducativeLevelT,
  agreementsByMunicipality,
} from 'api/statistic.api';
import { AgreementsByMunicipality } from 'components/Agreements/types';

export interface StatisticsSlice {
  companiesStat: CompanyStatistics[];
  companiesStatStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
  subsWorkCStats: SubWorkCStatistics[];
  subsWorkCStatsStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
  workCStats: SubWorkCStatistics[];
  workCStatsStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
  agreementsByEducativeLevel: AgreementsByEducativeLevelT[];
  agreementsByEducativeLevelStatus:
    | 'empty'
    | 'fulfilled'
    | 'error'
    | 'updating';
  agreementsByMunicipality: AgreementsByMunicipality[];
  agreementsByMunicipalityStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
  // studentsByEducativeLevelStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
}

const initialState: StatisticsSlice = {
  companiesStat: [],
  companiesStatStatus: 'empty',
  subsWorkCStats: [],
  subsWorkCStatsStatus: 'empty',
  workCStats: [],
  workCStatsStatus: 'empty',
  agreementsByEducativeLevel: [],
  agreementsByEducativeLevelStatus: 'empty',
  agreementsByMunicipality: [],
  agreementsByMunicipalityStatus: 'empty',
};

export const doListCompStatistics = createAsyncThunk(
  'agreements/compStatistics',
  async () => companiesStatistics().then((res) => res)
);

export const doListSubWorkCStatistics = createAsyncThunk(
  'agreements/subWorkCStatistics',
  async () => subsystemWorkCStatistics().then((res) => res)
);

export const doListWorkCStatistics = createAsyncThunk(
  'agreements/workCStatistics',
  async () => workCStatistics().then((res) => res)
);

export const doListAgreementsByEducativeLevel = createAsyncThunk(
  'statistic/agreementsByEducativeLevel',
  async () => agreementsByEducativeLevel().then((res) => res)
);

export const doListAgreementsByMunicipality = createAsyncThunk(
  'statistic/agreementsByMunicipality',
  async () => agreementsByMunicipality().then((res) => res)
);

export const doClear = createAction('statistics/clear', () => {
  return {
    payload: 'clear',
  };
});

// export const doListStudentsByEducativeLevel = createAsyncThunk(
//   'statistic/studentsByEducativeLevel',
//   async () => studentsByEducativelevel().then((res) => res)
// );

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doListCompStatistics.fulfilled, (state, action) => {
      state.companiesStat = action.payload;
      state.companiesStatStatus = 'fulfilled';
    });
    builder.addCase(doListSubWorkCStatistics.fulfilled, (state, action) => {
      state.subsWorkCStats = action.payload;
      state.subsWorkCStatsStatus = 'fulfilled';
    });
    builder.addCase(doListWorkCStatistics.fulfilled, (state, action) => {
      state.workCStats = action.payload;
      state.workCStatsStatus = 'fulfilled';
    });
    builder.addCase(doClear, (state, _action) => {
      state.subsWorkCStats = [];
      state.subsWorkCStatsStatus = 'empty';
      state.companiesStat = [];
      state.companiesStatStatus = 'empty';
      state.workCStats = [];
      state.workCStatsStatus = 'empty';
    });
    builder.addCase(
      doListAgreementsByEducativeLevel.fulfilled,
      (state, action) => {
        state.agreementsByEducativeLevelStatus = 'fulfilled';
        state.agreementsByEducativeLevel = action.payload;
      }
    );
    builder.addCase(doListAgreementsByMunicipality.fulfilled, (state, action) => {
      state.agreementsByMunicipalityStatus = 'fulfilled';
      state.agreementsByMunicipality = action.payload;
    });
  },
});

export default statisticsSlice.reducer;
