import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { StudentList } from "components/students/StudentList/StudentList";

const StudentsPage: React.FC = () => {
  return (
    <>
      <PageTitle>{`Lista de Alumnos`}</PageTitle>
      <StudentList />
    </>
  );
};

export default StudentsPage;
