import { httpApi } from "api/http.api";
import { Company, UpdateStatusCompanyProps } from "components/companies/types";
import { SearchProps } from "types";

const controller = `company`;
export type UpdateObject = {
  changedValues: Company,
  company: Company,
}

export const register = (companyPayload: Company): Promise<Company> =>
  httpApi
    .post<Company>(`${controller}`, { ...companyPayload })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
    
export const update = (companyPayload: UpdateObject): Promise<Company> =>
  httpApi
    .put<Company>(`${controller}/${companyPayload.company._id}`, {
      ...companyPayload,
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });

export const list = (): Promise<Company[]> =>
  httpApi.get<Company[]>(`${controller}`).then(({ data }) => data);

export const search = ({ searchParam, searchValue }: SearchProps) =>
  httpApi
    .get<Company[]>(`${controller}/search/${searchParam}/${searchValue}`)
    .then(({ data }) => data);

export const remove = (companyPayload: Company): Promise<Company> =>
  httpApi
    .delete(`${controller}`, { data: { id: companyPayload._id } })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });

export const listfilter = (rowCount: number): Promise<Company[]> =>
  httpApi.get<Company[]>(`${controller}/${rowCount}`).then(({ data }) => data);

export const statusUpdate = (status: UpdateStatusCompanyProps): Promise<Company> =>
  httpApi
    .patch<Company>(`${controller}/status`, { ...status })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
