import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FileTextOutlined } from "@ant-design/icons";
import { useAppSelector } from "hooks/reduxHooks";
import { notificationController } from "controllers/notificationController";
import { Agreement } from "../types";
import { estimateTotalHours } from "../utils/utils";
import { getHoursMinutesString } from "hooks/dateTreatment";
import { formatDate, formatDatesInObj, setYesOrNo } from "../utils/CSVFormatting";

type exportAgrementsProps = {
  module: string;
}
export const ExportAgreements = ({module}: exportAgrementsProps) => {
  // export csv
  const { agreements, inactiveAgreements } = useAppSelector((state) => state.agreement);
  const [data, setData] = useState<Agreement[]>([])
  const [label, setLabel] = useState('');

  useEffect(() => {
    let btnLabel=''
    if(module==='agreement') {
      setData(agreements);
      btnLabel='';
    } else {
      setData(inactiveAgreements);
      btnLabel='inactivos';
    }
    setLabel(btnLabel);
  }, [module])

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const hanldeExportButton = async () => {
    csvLinkRef?.current?.link.click();
    notificationController.success({
      message: "Archivo descargado ",
    });
  };
  
  const headers = [
    { label: "folio", key: "sequence" },
    { label: "estado", key: "status" },
    { label: "Fecha de registro", key: "createdAt" },
    { label: "Fecha de inicio", key: "startDate" },
    { label: "Fecha de finalización", key: "endDate" },
    { label: "Horas a la semana", key: "hoursPerWeek" },
    { label: "Proyección de horas", key: "hoursProjection" },
    { label: "Horas completadas del convenio finalizado", key: "completedHours" },
    { label: "Horas acumuladas del alumno", key: "studentCumulative" },
    { label: "Estudiante nombres", key: "studentId.name" },
    { label: "Estudiante apellido 1", key: "studentId.paternalLastname" },
    { label: "Estudiante apellido 2", key: "studentId.maternalLastname" },
    { label: "Correo del estudiante", key: "studentId.email" },
    { label: "Carrera del estudiante", key: "studentId.career" },
    { label: "CURP del estudiante", key: "studentId.curp" },
    { label: "Género del estudiante", key: "studentId.gender" },
    { label: "Estado académico del estudiante", key: "studentId.academicStatus" },
    { label: "Teléfono del estudiante", key: "studentId.phone" },
    { label: "Fecha de nacimiento del estudiante", key: "studentId.birthDate" },
    { label: "Tutor del estudiante", key: "studentId.tutor" },
    { label: "Fecha de finalización del estudiante", key: "studebtId.completionDate" },
    { label: "Numero de puestos de rotación del estudiante", key: "rotationTimes" },
    { label: "Puestos de rotación del estudiante", key: "rotationPositions" },
    { label: "Plantel", key: "workCenterId.name" },
    { label: "Plantel - nombre corto", key: "workCenterId.nameShort" },
    { label: "Subsistema", key: "workCenterId.subsystem" },
    { label: "Subsistema - nombre corto", key: "workCenterId.subsystemShort" },
    { label: "Director firmante", key: "principal.name" },
    { label: "Correo del director firmante", key: "principal.email" },
    { label: "Teléfono del director firmante", key: "principal.phone" },
    { label: "Puesto del director firmante ", key: "principalPosition" },
    { label: "Puesto actual del director firmante", key: "principal.position" },
    { label: "Director general del subsistema", key: "workCenterId.currentSubsystemPrincipal" },
    { label: "Razón social de la empresa", key: "companyId.name" },
    { label: "Nombre comercial de la empresa", key: "companyId.commercialName" },
    { label: "Estado de la empresa", key: "companyId.status" },
    { label: "Giro de la empresa", key: "companyId.industry" },
    { label: "Nombre del contacto de la empresa", key: "representativeId.name" },
    { label: "Correo del contacto de la empresa", key: "representativeId.email" },
    { label: "Teléfono del contacto de la empresa", key: "representativeId.phone" },
    { label: "Puesto del contacto de la empresa", key: "representativeId.position" },
    { label: "Estado del contacto de la empresa", key: "representativeId.status" },
    { label: "Convenio de aprendizaje", key: "learningAgreementFileId" },
    { label: "Convenio de colaboración", key: "colaborationAgreementFileId" },
    { label: "Carta de autorización de tutor", key: "tutorAuthLetterFileId" },
    { label: "Identificación de tutor", key: "tutorIdFileId" },
    { label: "Seguro facultativo", key: "insuranceFileId" },
    { label: "Plan de rotación", key: "rotationPlanFileId" },
    { label: "Puestos de aprendizaje", key: "learningPositionsFieldId" },
    { label: "Matriz de correspondencia", key: "matrixFieldId" },
    { label: "Nombramiento del director", key: "principalFileId" },
    { label: "Responsiva de laptop", key: "laptopAssignmentFileId" },
  ];
  return (
    <>
      <CSVLink
        data={data.map((ag) => {
          const newAG = formatDatesInObj(ag)
          const hoursProjectionObj = estimateTotalHours(ag.startDate as string, ag.endDate as string, ag.hoursPerWeek ?? 0);
          const hoursProjectionStr = hoursProjectionObj ?  getHoursMinutesString(hoursProjectionObj) : 'Sin registro';
          return {
            ...newAG,
            studentCumulative: ag.studentId.completedHours
              ? ag.studentId.completedHours.map((item) =>
                  item.educativeLevel === 'SUPERIOR'
                    ? 'ES'
                    : 'EMS' + ': ' + getHoursMinutesString(item.completedHours)
                )
              : 'Sin registro',
            hoursProjection: hoursProjectionStr,
            completedHours: ag.completedHours
              ? getHoursMinutesString(ag.completedHours)
              : 'Sin registro',
            startDate: formatDate(ag.startDate),
            endDate: formatDate(ag.endDate),
            createdAt: formatDate(ag.createdAt),
            learningAgreementFileId: setYesOrNo(ag.learningAgreementFileId),
            colaborationAgreementFileId: setYesOrNo(
              ag.colaborationAgreementFileId
            ),
            tutorAuthLetterFileId: setYesOrNo(ag.tutorAuthLetterFileId),
            tutorIdFileId: setYesOrNo(ag.tutorIdFileId),
            insuranceFileId: setYesOrNo(ag.insuranceFileId),
            rotationPlanFileId: setYesOrNo(ag.rotationPlanFileId),
            learningPositionsFieldId: setYesOrNo(ag.learningPositionsFieldId),
            matrixFieldId: setYesOrNo(ag.matrixFieldId),
            principalFileId: setYesOrNo(ag.principalFileId),
            laptopAssignmentFileId: setYesOrNo(ag.laptopAssignmentFileId),
          };
        })}
        headers={headers}
        className="exportButton"
        filename={`expedientes${label}.csv`}
        ref={csvLinkRef}
      ></CSVLink>
      <Button type="primary" block onClick={hanldeExportButton}>
        <p>
          <FileTextOutlined /> Exportar expedientes {label}
        </p>
      </Button>
    </>
  );
};
