import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}
export const AgreementsNavigationItems: SidebarNavigationItem[] = [
  {
    title: "Nuevo",
    key: "newAgreement",
    url: "/expedientes/nuevo",
    icon: <FontAwesomeIcon icon={solid("plus")} />
  },
  {
    title: "Seguimiento",
    key: "agreementsFollow",
    url: "/seguimiento",
    icon: <FontAwesomeIcon icon={solid("arrow-right-arrow-left")} />,
  },
  {
    title: "En Revisión",
    key: "agreementsFollowPending",
    url: "/expedientes/pendientes",
    icon: <FontAwesomeIcon icon={solid("cubes-stacked")} />,
  },
  {
    title: "Incompletos",
    key: "agreementsDraft",
    url: "/expedientes/incompletos",
    icon: <FontAwesomeIcon icon={solid("star-half")} />,
  },
  {
    title: "Necesitan correcciones",
    key: "agreementsrejected",
    url: "/expedientes/concorrecciones",
    icon: <FontAwesomeIcon icon={solid("star-half-stroke")} />,
  },
  {
    title: "Lista",
    key: "agreementsList",
    url: "/expedientes",
    icon: <FontAwesomeIcon icon={solid("list-ul")} />,
  },
  {
    title: "Bajas",
    key: "agreementsDown",
    url: "/expedientes/bajas",
    icon: <FontAwesomeIcon icon={solid("circle-down")} />,
  },
]
export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "Inicio",
    key: "dashboard",
    // TODO use path variable
    url: "/",
    icon: <FontAwesomeIcon icon={solid("gauge")} />,
  },
  {
    title: "Colaboradores",
    key: "employees",
    url: "/colaboradores",
    icon: <FontAwesomeIcon icon={solid("school")} />,
  },
  {
    title: "Expedientes",
    key: "agreements",
    icon: <FontAwesomeIcon icon={solid("book")} className="submenuIcon"/>,
    children: AgreementsNavigationItems,
  },
  {
    title: "Base de Datos de Alumnos",
    key: "alumnos",
    url: "/alumnos",
    icon: <FontAwesomeIcon icon={solid("users")} />,
  },
  {
    title: "Empresas",
    key: "empresas",
    url: `/empresas`,
    icon: <FontAwesomeIcon icon={solid("briefcase")} />,
  },
  {
    title: "Planteles",
    key: "workcenters",
    url: "/planteles",
    icon: <FontAwesomeIcon icon={solid("school")} />,
  },
  {
    title: "Subsistemas / IES",
    key: "subsystems",
    url: "/subsistemas",
    icon: <FontAwesomeIcon icon={solid("landmark")} />,
  },
  {
    title: "Salir",
    key: "logout",
    url: "/auth/logout",
    icon: <FontAwesomeIcon icon={solid("right-from-bracket")} />,
  }
];
