import { httpApi } from "api/http.api";
import { Tourmodules } from "components/employees/types";
import { User } from "types";

const controller = `user`;

export const completeTour = (module: typeof Tourmodules[number]): Promise<User> =>
  httpApi
    .put<User>(`${controller}/tour/${module}`)
    .then(({ data }) => data);
