import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import User from 'types/User';
import { persistUser, readUser } from 'services/localStorage.service';
import * as Sentry from "@sentry/react";
import { completeTour } from 'api/user.api';
import { Tourmodules } from 'components/employees/types';

export interface UserState {
  user: User | null;
  isSentryUserSet: boolean;
}

const initialState: UserState = {
  user: readUser(),
  isSentryUserSet: false,
};

export const setUser = createAction<PrepareAction<User>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const doCompleteTour = createAsyncThunk(
  "user/completeTour",
  async (module: typeof Tourmodules[number]) => completeTour(module).then((res) => res)
);

export const setSentryUser = createAction('user/sentry', (userToSet) => {
  return {
    payload: userToSet,
  };
});

export const doClear = createAction('user/clear', () => {
  return {
    payload: "clear",
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
      Sentry.setUser({ email: action.payload.email, id: action.payload._id });
      state.isSentryUserSet = true;
    });
    builder.addCase(setSentryUser, (state, action) => {
      Sentry.setUser({ email: action.payload.email, id: action.payload._id });
      state.isSentryUserSet = true;
    });
    builder.addCase(doCompleteTour.fulfilled, (state, action) => {
      state.user = {...state.user, ...action.payload};
      persistUser(state.user);
    });
    builder.addCase(doClear, (state, _action) => {
      state.user = null;
      state.isSentryUserSet = false;
      Sentry.setUser(null);
    });
  },
});

export default userSlice.reducer;
