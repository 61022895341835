import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Divider, Modal, Row } from 'antd';
import Dropzone from 'react-dropzone';
import {
  deleteFileById,
  getFileBufferbyId,
  getPresignedUrl,
} from 'api/file.api';
import { readToken } from 'services/localStorage.service';
import { notificationController } from 'controllers/notificationController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Document } from 'components/common/Document/Document';
import { ShowDocumentBtn } from '../Document/ShowDocumentBtn';

interface AWSFileUploaderPops {
  title: string;
  onUploadCompleted: (selectedFiles: string | null) => void;
  onClearField?: () => void;
  bucket: string;
  isReplacing?: boolean | string;
  inheritedFileId?: string;
}

export const AWSFileUploader: React.FC<AWSFileUploaderPops> = ({
  onUploadCompleted,
  title,
  bucket,
  isReplacing = false,
  inheritedFileId,
}) => {
  const [selectedFiles, setFiles] = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasUploadedPDF, setHasUploadedPDF] = useState<boolean>(false);
  const [uploadedPdfId, setUploadedPdfId] = useState<string>();

  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState('');

  const accepted = { pdf: ['.pdf'] };

  const { confirm } = Modal;

  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const selectPreloadedFile = async () => {
    const arrayBuffer = await getFileBufferbyId(inheritedFileId!);
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const pdfFile = new File([blob], 'file.pdf', { type: 'application/pdf' });
    onDrop([pdfFile]);
    notificationController.success({
      message: 'Se ha seleccionado correctamente el archivo',
    });
  };

  const onDrop = (acceptedFiles: File | ConcatArray<File>) => {
    setHasUploadedPDF(true);
    setFiles(acceptedFiles as File[]);
  };

  const handlerUpload = async () => {
    setConfirmLoading(true);
    let filesUploaded = null;
    try {
      if (hasUploadedPDF) {
        for await (const file of selectedFiles) {
          const preObject = await getPresignedUrl();
          const form = new FormData();
          form.append('file', file);
          if (preObject.storageType === 'cloud') {
            await fetch(preObject.url, {
              method: 'PUT',
              body: form,
            });
          }
          if (preObject.storageType === 'disk') {
            form.append('bucket', bucket);
            const response = await fetch(preObject.url, {
              method: 'PUT',
              body: form,
              headers: new Headers({
                Authorization: `Bearer ${readToken()}`,
              }),
            });
            if (!response.ok) {
              const message = `An error occured: ${response.status}`;
              throw new Error(message);
            }
          }
          filesUploaded = preObject.fileId;
        }
        if (filesUploaded !== null) {
          setUploadedPdfId(filesUploaded);
          onUploadCompleted(filesUploaded);
        } else {
          onUploadCompleted(uploadedPdfId as string);
        }
        setHasUploadedPDF(true);
      } else {
        if (uploadedPdfId === undefined) onUploadCompleted(null);
      }
      setConfirmLoading(false);
      setOpen(false);
    } catch (error) {
      setConfirmLoading(false);
      notificationController.error({
        message:
          'Error al subir archivo, por favor vuelve a intentar más tarde',
      });
    }
  };

  const onDeleteFile = async (id: string) => {
    if (id) {
      const currentRoute = window.location.pathname;
      const documentId = currentRoute.split('/').pop();
      const typeOfDocument = currentRoute.split('/')[1];

      const headers = new Headers({
        Authorization: `Bearer ${readToken()}`,
      });
      const config = {
        data: { document: typeOfDocument, documentId },
        headers: Object.fromEntries(headers),
      };
      const response = await deleteFileById(id, config);

      if (response.status !== 200) {
        notificationController.error({
          message: `El archivo no pudo ser eliminado`,
        });
        const message = `An error occured: ${response.status}`;
        throw new Error(message);
      }
    }
    notificationController.success({
      message: `Se ha eliminado el archivo`,
    });
    return true;
  };

  const handleDeleteCurrent = async () => {
    confirm({
      title: '¿Está seguro de eliminar el archivo adjunto?',
      icon: (
        <FontAwesomeIcon
          icon={solid('triangle-exclamation')}
          style={{
            color: '#ce0e2c',
            height: '48px',
            marginRight: '15px',
          }}
        />
      ),
      content: 'Si elimina el archivo ya no se podrá recuperar',
      async onOk() {
        const confirmDeleted = await onDeleteFile(uploadedPdfId as string);
        if (confirmDeleted) {
          setUploadedPdfId(undefined);
          setHasUploadedPDF(false);
          setFiles([]);
          onUploadCompleted(null);
        }
      },
    });
  };

  useEffect(() => {
    if (isReplacing !== undefined && typeof isReplacing === 'string') {
      setUploadedPdfId(isReplacing);
      setHasUploadedPDF(true);
    }
  }, [isReplacing]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          return false;
        }}
      >
        Subir Archivo PDF
      </Button>
      <Modal
        title={title ? title : `Adjuntar archivo`}
        open={open}
        okText={`Guardar cambios`}
        cancelText='Cancelar'
        onOk={handlerUpload}
        onCancel={() => {
          if (!hasUploadedPDF) setFiles([]);
          if (selectedFiles === null) setHasUploadedPDF(false);
          setOpen(false);
        }}
        confirmLoading={confirmLoading}
      >
        <Alert message='Solo se acepta un archivo en formato PDF' type='info' />
        {!hasUploadedPDF && selectedFiles.length === 0 ? (
          <Dropzone onDrop={onDrop} maxFiles={1} accept={accepted}>
            {({ getRootProps, getInputProps }) => (
              <section className='drop-zone'>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FontAwesomeIcon
                    icon={solid('cloud-arrow-up')}
                    style={{
                      color: '#91CAFF',
                      marginRight: '5px',
                      height: '64px',
                    }}
                  />
                  <div>Arrastra o da click para seleccionar el archivo</div>
                </div>
              </section>
            )}
          </Dropzone>
        ) : (
          (hasUploadedPDF || selectedFiles.length > 0) && (
            <div className='file-replace-warning'>
              <div className='file-r-name'>
                <p>Archivo actual</p>
                <div className='file-row'>
                  <div className='file-ico'>
                    <FontAwesomeIcon
                      icon={solid('file-pdf')}
                      style={{
                        color: '#ce0e2c',
                        height: '48px',
                        marginRight: '15px',
                      }}
                    />
                  </div>
                  <div className='file-details'>
                    <div className='file-name'>{title}.pdf</div>
                    <div className='file-options'>
                      <FontAwesomeIcon
                        icon={solid('trash')}
                        style={{ color: '#ce0e2c' }}
                        onClick={handleDeleteCurrent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        {inheritedFileId && (
          <>
            <br />
            <Divider style={{ margin: '15px 0px' }} />
            <Row>Selecciona desde un archivo existente:</Row>
            <br />
            <Row>
              <Col flex={1}>
                <ShowDocumentBtn
                  label={title}
                  onClick={() => {
                    onShowDocument(inheritedFileId);
                  }}
                />
              </Col>
              <Col>
                <Button
                  disabled={hasUploadedPDF}
                  type='primary'
                  onClick={selectPreloadedFile}
                >
                  {hasUploadedPDF
                    ? 'Hay un archivo seleccionado'
                    : 'Copiar este archivo'}
                </Button>
              </Col>
            </Row>
            <Divider style={{ marginTop: '15px' }} />
            <Document
              fileId={pdfViewerId}
              open={showDocument}
              onCancel={() => {
                setShowDocument(false);
                setPdfViewerId('');
              }}
              onOk={() => setShowDocument(false)}
            />
          </>
        )}
      </Modal>
    </>
  );
};
