import { Button, Popover, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FileTextOutlined } from "@ant-design/icons";
import { notificationController } from "controllers/notificationController";
import { CsvEmployees } from "./CsvEmployees";
import { loginReport } from "api/employee.api";
import { LoginReportType } from "../types";
import { CsvLoginReport } from "./CsvLoginReport";

export const ExportEmployees = () => {
  // export csv

  const [loginRepData, setLoginRepData] = useState<LoginReportType[] | null>(null)
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const csvReportLinkRef = useRef<
  CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
>(null);

useEffect(() => {
  if(loginRepData && loginRepData !== null) {
    csvReportLinkRef?.current?.link.click();
    notificationController.success({
      message: "Archivo descargado ",
    });
  }
}, [loginRepData])

  const handleExportEmpButton = async () => {
    csvLinkRef?.current?.link.click();
    notificationController.success({
      message: "Archivo descargado ",
    });
  };

  const handleExportLoginButton = async () => {
    const data = await loginReport();
    setLoginRepData(data);
  };

  return (
    <>
      <CsvEmployees csvLinkRef={csvLinkRef} />
      {loginRepData && loginRepData !== null && (<CsvLoginReport csvReportLinkRef={csvReportLinkRef} data={loginRepData}/>)}
      <Popover
        style={{
          backgroundColor: "grey",
          WebkitBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
          boxShadow: "0px 1px 18px 0px rgba(0,0,0,0.75)",
          MozBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
        }}
        placement="bottom"
        content={
          <>
            <Row>
              <Button
                size="small"
               onClick={handleExportLoginButton}
              >
                Reporte de inicio de sesión
              </Button>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Button type="primary" size="small" onClick={handleExportEmpButton}>
                Reporte de usuarios
              </Button>
            </Row>
          </>
        }
        trigger="click"
      >
        <Button type="primary">
          <p>
            <FileTextOutlined /> Exportar colaboradores
          </p>
        </Button>
      </Popover>
    </>
  );
};
