import { Descriptions, Divider, Drawer } from "antd";
import { WorkCenter, initialValues } from "./types";
import { OptionsDrawerBar } from "./OptionsDrawer";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { doList } from "store/slices/subsystemSlice";

const {writeWorkcenters} = PermissionsToAccess


type WorkcenterDetailProps = {
  selectedRow: WorkCenter | null;
  onClose: () => void;
  open: boolean;
};

export const WorkcenterDetail = ({
  selectedRow,
  open,
  onClose,
}: WorkcenterDetailProps) => {
  const dispatch = useAppDispatch();
  const { subsystems, status: subsystemStateStatus } = useAppSelector(
    (state) => state.susbsystem
  ); 
    useEffect(() => {
      if (subsystemStateStatus !== 'fulfilled') dispatch(doList());
    }, [subsystemStateStatus]);

  const [educativeLevel, setValueEducativeLevel] = useState('');
  useEffect(() => {
    if (selectedRow?.subsystemId !== undefined) {
      const subsystem = subsystems.find(sub => sub._id === selectedRow.subsystemId);
      setValueEducativeLevel(subsystem?.educativeLevel !== undefined ? subsystem.educativeLevel : '');
    }
  }, [selectedRow, subsystems]);
   return (
    <Drawer
      title={`Detalles del plantel`}
      placement="right"
      open={open}
      destroyOnClose
      onClose={onClose}
      width={`50%`}
      extra={
        CheckAccess(writeWorkcenters) && [
          <OptionsDrawerBar rowSelected={selectedRow ?? initialValues} />,
        ]
      }
    >
      {selectedRow && (
        <>
          <Divider>Plantel</Divider>
          <Descriptions bordered size="small">
            <Descriptions.Item key="name" label="Nombre completo" span={3}>
              {selectedRow.name}
            </Descriptions.Item>
            <Descriptions.Item
              key="principal"
              label="Director(a) del plantel "
              span={3}
            >
              {selectedRow.principal ? selectedRow.principal.name : ""}
            </Descriptions.Item>
            <Descriptions.Item
              key="position"
              label="Posición del director(a) "
              span={3}
            >
              {selectedRow.principal && selectedRow.principal.position !== undefined  
              ? selectedRow.principal.position : ""}
            </Descriptions.Item>
            <Descriptions.Item
              key="municipality"
              label="Municipio del plantel"
              span={3}
            >
              {selectedRow.municipality && selectedRow.municipality !== undefined  
              ? selectedRow.municipality.name : ""}
            </Descriptions.Item>
            <Descriptions.Item
              key="keySep"
              label="CCT"
              span={3}
            >
              {selectedRow.keySep ?? ""}
            </Descriptions.Item>
          </Descriptions>

          <Divider>Subsistema</Divider>
          <Descriptions bordered size="small">
            <Descriptions.Item
              key="name"
              label="Nombre del Subsistema / IES"
              span={3}
            >
              {selectedRow.subsystem}
            </Descriptions.Item>
            <Descriptions.Item
              key="nameShort"
              label="Nombre corto"
              span={3}
            >
              {selectedRow.subsystemShort}
            </Descriptions.Item>
            <Descriptions.Item
              key="educativeLevel"
              label="Nivel educativo del subsistema"
              span={3}
            >
              {educativeLevel}
            </Descriptions.Item>
            <Descriptions.Item
              key="principal"
              label="Director(a) general del subsistema / rector(a) de la IES "
              span={3}
            >
              {selectedRow.currentSubsystemPrincipal ?? ""}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Drawer>
  );
};
