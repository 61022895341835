import { Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space } from "../../inputs/SearchInput/SearchInput.styles";

type InfoButtonProprs = {
  onClick: () => void;
};

export const InfoButton = ({ onClick }: InfoButtonProprs) => (
  <Space>
    <Button
      shape="circle"
      size="large"
      type="text"
      className="info-btn"
      icon={<QuestionCircleOutlined />}
      onClick={onClick}
    ></Button>
  </Space>
);
