import { AccessLvls } from "components/employees/types";

export const PermissionsToAccess = {
    readAgreements: {module: "agreement", permission:AccessLvls.readOnly},
    writeAgreements: {module: "agreement", permission:AccessLvls.readWrite},
    editApprovedAgreements: {module: "approvedAgreement", permission:AccessLvls.readWrite},

    readCompanies: {module: "company", permission:AccessLvls.readOnly},
    writeCompanies: {module: "company", permission:AccessLvls.readWrite},

    readEmployees: {module: "user", permission:AccessLvls.readOnly},
    writeEmployees: {module: "user", permission:AccessLvls.readWrite},

    readStudents: {module: "student", permission:AccessLvls.readOnly},
    writeStudents: {module: "student", permission:AccessLvls.readWrite},

    readSubsystems: {module: "subsystem", permission:AccessLvls.readOnly},
    writeSubsystems: {module: "subsystem", permission:AccessLvls.readWrite},

    readWorkcenters: {module: "workcenter", permission:AccessLvls.readOnly},
    writeWorkcenters: {module: "workcenter", permission:AccessLvls.readWrite},

    exportAgreements: {module: "export",  permission:AccessLvls.readWrite }
}
