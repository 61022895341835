import { List } from "antd";
import { CommentsType } from "components/Agreements/types";
import { valueDate } from "hooks/dateTreatment";
import { ReactNode } from "react";
import { Company } from "./types";
export type CommentsProp = {
  selectedRow: Company;
};

export const ComentsCompany = ({ selectedRow }: CommentsProp) => {
  const FileValues = (item: CommentsType, _index: number): ReactNode => {
    return (
      <List.Item>
        <List.Item.Meta
          title={`${item.createdAt ? valueDate(item.createdAt, true) : ""}: ${
            item.by
          }:  `}
        />
        {item.body}
      </List.Item>
    );
  };
  return (
    <List
      size='small'
      dataSource={selectedRow.comments ?? []}
      renderItem={FileValues}
    />
  );
};
