import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { agreementsApprovalProcess, fileStorageConfig } from "api/config.api";
import { ApprovementRoleType } from "components/employees/types";

export interface ConfigSlice {
  validApprovalRoles: ApprovementRoleType[];
  configStatus: 'empty' | 'fulfilled' | 'error' | 'updating' ;
  storageType: string;
  storageTypeStatus: 'empty' | 'fulfilled' | 'error' | 'updating' ;

}

const initialState: ConfigSlice = {
  validApprovalRoles:[],
  configStatus: 'empty',
  storageType:'',
  storageTypeStatus: 'empty',
};


export const doGetApprovalProcess = createAsyncThunk("config/approvalProcess", async () =>
  agreementsApprovalProcess().then((res) => res)
);
export const doGetFileStorageConfig = createAsyncThunk("config/fileStorageConfig", async () =>
fileStorageConfig().then((res) => res)
);
export const doClear = createAction('config/clear', () => {
  return {
    payload: "clear",
  };
});


const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetApprovalProcess.fulfilled, (state, action) => {
      state.validApprovalRoles = action.payload;
      state.configStatus = 'fulfilled';
    });
    builder.addCase(doGetFileStorageConfig.fulfilled, (state, action) => {
      state.storageType = action.payload;
      state.storageTypeStatus = 'fulfilled';
    });
    builder.addCase(doClear, (state, _action) => {
      state.validApprovalRoles = [];
      state.configStatus = "empty";
      state.storageType = '';
      state.storageTypeStatus = 'empty';
    });
  },
});

export default configSlice.reducer;
