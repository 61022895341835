import styled from 'styled-components';
import { Card as CommonCard } from 'components/common/Card/Card';
import { Button } from 'antd';
import { FONT_SIZE, FONT_WEIGHT } from 'styles/themes/constants';

export const Wrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const colorStatus = (status: string) => {
  status = status.toLocaleLowerCase();
  switch (status) {
    case "graduado":
    case "egresado":
    case "activo":
      return "#329213";
    case "baja temporal":
      return "#FF8C00";
    case "baja definitiva":
    case "baja":
      return "#cc002e";
    default:
      return "#000000";
  }
};