export type MunicipalityRegionsT = 'METROPOLITANA' | 'PERIFÉRICA' | 'RURAL';

export type Municipality = {
  _id?: string;
  name: string;
  region: MunicipalityRegionsT;
};

export type MunicipalitiesByRegion = {
  region: string;
  municipalities: Municipality[];
};

export const initualValuesMunicipalitiesByRegion: MunicipalitiesByRegion = {
  region: '',
  municipalities: [],
};

export const initialValuesMunicipality: Municipality = {
  name: '',
  region: 'METROPOLITANA',
};

// const areaMetropolitana = [
//   'MONTERREY',
//   'GUADALUPE',
//   'APODACA',
//   'SAN NICOLAS',
//   'GRAL. ESCOBEDO',
//   '  SANTA CATARINA',
//   'JUÁREZ',
//   'MONTEMORELOS',
//   'SAN PEDRO GARZA GARCÍA',
// ];

// const areaPeriferica = [
//   'CADEREYTA',
//   'GARCÍA',
//   'PESQUERÍA',
//   'GRAL. ZUAZUA',
//   'CIÉNEGA DE FLORES',
// ];

// const areaRural = [
//   'LINARES',
//   'ALLENDE',
//   'MARÍN',
//   'SANTIAGO',
//   'DR. ARROYO',
//   'GALEANA',
// ];

// export const municipalityRegions = ['METROPOLITANA', 'PERIFÉRICA', 'RURAL'];

// export const workCenterRegionsOptions = workCenterRegions.map((region) => ({
//   label: region,
//   value: region,
//   key: region,
// }));
