import {
  Descriptions,
} from "antd";
import { ReactNode } from "react";
import { WorkCenter } from "components/workcenters/types";
import { Employee } from "../types";
import { getStatusLights } from "../Employees.styles";

type EmployeeDetailProps = {
  selectedRow: Employee;
};

export const EmployeeDetail = ({ selectedRow }: EmployeeDetailProps) => {
  const workCenterData = (item: WorkCenter): ReactNode => {
    return (
      <Descriptions bordered size="small" title="Plantel">
        <Descriptions.Item key="workName" label="Nombre">
          {item.nameShort ?? ""}
        </Descriptions.Item>
        <Descriptions.Item key="subsystem" label="Nombre Completo" span={2}>
          {item.name ?? ""}
        </Descriptions.Item>
        <Descriptions.Item key="subsystem" label="Subsistema">
          {item.subsystemShort ?? "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item key="subsystem" label="Subsistema Completo" span={2}>
          {item.subsystem ?? "No hay Registro"}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <>
      <Descriptions bordered size="small">
        <Descriptions.Item key="name" label="Nombre" span={2}>
          {selectedRow.name}
        </Descriptions.Item>
        <Descriptions.Item key="status" label="Estado">
          <p style={{ color: getStatusLights(selectedRow.status) }}>
            {selectedRow.status ? "activo" : "Inactivo"}
          </p>
        </Descriptions.Item>
        <Descriptions.Item key="email" label="Correo electrónico" span={2}>
          {selectedRow.email ?? "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item key="phone" label="Teléfono" span={2}>
          {selectedRow.phone ?? "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item key="position" label="Puesto" span={2}>
          {selectedRow.position ?? "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item key="subsystem" label="Subsistema" span={2}>
          {selectedRow.subsystem ?? "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item key="approvementRole" label="Rol de aprobación para expedientes" span={2}>
          {selectedRow.approvementRole ? selectedRow.approvementRole.name : "No hay Registro"}
        </Descriptions.Item>
      </Descriptions>
      <br />
      {selectedRow.workcenter
        ? workCenterData(selectedRow.workcenter)
        : "Contacta al Administrador"}
      <br />
    </>
  );
};
