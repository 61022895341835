import React, { useEffect, useState } from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList as companyDoList } from "store/slices/companySlice";
import { Company, Representative } from "components/companies/types";
import { Alert } from "antd";
import * as Sentry from "@sentry/react"
import { CompanyEdit } from "components/companies/CompanyEdit";
import { Card } from "components/common/Card/Card.styles";
import { doList as repsDoList } from "store/slices/representativeSlice";
import { COMPANY_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "components/common/buttons/Button/CloseButton";

const CompanyEditPage: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companies, status: companyStateStatus } = useAppSelector((state) => state.company);
  const { representatives, status: repsStateStatus } = useAppSelector((state) => state.representative);
  const [ company, setCompany ] = useState<Company | null>();
  const [ companyReps, setCompanyReps ] =  useState<Representative[]>([]);
  const [ companyContacts, setCompanyContacts ] =  useState<Representative[]>([]);
  useEffect(() => {
    if (companyStateStatus === 'empty'){
      dispatch(companyDoList());
    } else if (companyStateStatus === 'fulfilled' && params.id) {
      const currentCompany: Company | undefined = companies.filter((c) => c && c._id === params.id).at(0);
      if ( currentCompany ) {
        setCompany(currentCompany);
      } else {
        Sentry.captureMessage("Not able to find a company to edit");
      }
    }
    if ( repsStateStatus !== `fulfilled` ){
      dispatch(repsDoList())
    } else {
      const currentReps: Representative[] = representatives.filter((r) => (r && r.companyId ===  params.id) && r.type === "Representative");
      setCompanyReps(currentReps);
      const currentContacts: Representative[] = representatives.filter((r) => (r && r.companyId ===  params.id) && r.type === "Contact");
      setCompanyContacts(currentContacts);
    }
  },[params, companies, companyStateStatus, dispatch, representatives, repsStateStatus]);

  return (
    <>
      <PageTitle>{`Editar Empresa`}</PageTitle>
      {companyStateStatus === `empty` && (
        <Alert type="info" message={`Cargando información de la empresa...`} />
      )}
      {companyStateStatus === `updating` && (
        <Alert
          type="info"
          message={`Actualizando información de la empresa...`}
        />
      )}
      {companyStateStatus === `fulfilled` && company === null && (
        <Alert type="error" message={`Error al cargar la empresa`} />
      )}
      {companyStateStatus === `fulfilled` && company && (
        <Card
          $autoHeight={true}
          $padding={`2`}
          id="edit-company"
          title={
            <>
              <CloseButton onClick={()=>{navigate(COMPANY_PATH)}}></CloseButton>
              <span>&nbsp; {`Editar empresa ${company.name}`}</span>
            </>
          }
        >
          <CompanyEdit company={company} representatives={companyReps} contacts={companyContacts}/>
        </Card>
      )}
    </>
  );
};

export default CompanyEditPage;
