import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { SubsystemList } from "components/subsystems/SubsystemList";

const SubsystemListPage: React.FC = () => {
  return (
    <>
      <PageTitle>{`Lista de subsistemas`}</PageTitle>
      <SubsystemList />
    </>
  );
};

export default SubsystemListPage;
