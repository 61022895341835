import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { CompanyList } from "components/companies/CompanyList";

const CompanyPage: React.FC = () => {
  return (
    <>
      <PageTitle>{`Lista de Empresas`}</PageTitle>
      <CompanyList />
    </>
  );
};

export default CompanyPage;
