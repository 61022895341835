import { AgreementNewForm } from "components/Agreements/AgreementNewForm";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import React from "react";

const AgreementNewPage: React.FC = () => {

  return (
    <>
       <PageTitle>{`Nuevo Expediente`}</PageTitle>
      <AgreementNewForm />
    </>
  );
};

export default AgreementNewPage;
