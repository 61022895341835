import { Button, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type showDocBtnPros = {
    onClick: () => void;
    label?: string;
};

export const ShowDocumentBtn = ({onClick, label}: showDocBtnPros) => (
  <Space>
    <Button
      type="link"
      onClick={onClick}
      icon={
        <FontAwesomeIcon
          icon={solid("file-pdf")}
          style={{ color: "#ce0e2c", marginRight: "5px" }}
        />
      }
    >
      {label ?? "ver documento"}
    </Button>
  </Space>
);
