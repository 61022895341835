import { EditAgreement } from "components/Agreements/edit/EditAgreement";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import React from "react";

const AgreementEditPage: React.FC = () => {
  const params = useParams();
  return (
    <>
      <PageTitle>{`Editar expediente`}</PageTitle>
      <EditAgreement agreementId={JSON.parse(JSON.stringify(params))} />
    </>
  );
};

export default AgreementEditPage;
