import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { FollowList } from "components/follow/FollowList";

const FollowPage: React.FC = () => {
    return (
        <>
            <PageTitle>{`Seguimiento de expedientes`}</PageTitle>
            <FollowList/>
        </>
    )
}

export default FollowPage;