import React, { useEffect, useState } from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { Subsystem } from "components/subsystems/types";
import { Alert } from "antd";
import * as Sentry from "@sentry/react";
import { Card } from "components/common/Card/Card.styles";
import { SUBSYSTEMS_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { SubsystemEdit } from "components/subsystems/SubsystemEdit";

const SubsystemEditPage: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { subsystems, status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  const [subsystem, setSubsystem] = useState<Subsystem | null>(null);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    if (subsystems.length > 0 && params.id) {
      const currentSubsystem: Subsystem | undefined = subsystems
        .filter((c) => c && c._id === params.id)
        .at(0);
      if (currentSubsystem) {
        setSubsystem(currentSubsystem);
      } else {
        Sentry.captureMessage("Not able to find a subsystem to edit");
      }
    }
  }, [params, subsystems, subsystemStateStatus, dispatch]);

  return (
    <>
        <PageTitle>{`Editar Subsistema`}</PageTitle>
        {subsystemStateStatus === `empty` && (
          <Alert
            type="info"
            message={`Cargando información del subsistema...`}
          />
        )}
        {subsystemStateStatus === `updating` && (
          <Alert
            type="info"
            message={`Actualizando información del subsistema...`}
          />
        )}
        {subsystemStateStatus === `fulfilled` && subsystem === null && (
          <Alert type="error" message={`Error al cargar el subsistema`} />
        )}
        {subsystemStateStatus === `fulfilled` && subsystem && (
          <Card
            $autoHeight={true}
            $padding={`2`}
            id="edit-subsystem"
            title={
              <>
                <CloseButton
                  onClick={() => {
                    navigate(SUBSYSTEMS_PATH);
                  }}
                ></CloseButton>
                <span>&nbsp; {`Editar subsistema  / IES (Instituto de Educación Superior): ${subsystem.name}`}</span>
              </>
            }
          >
            <SubsystemEdit subsystem={subsystem} />
          </Card>
        )}
    </>
  );
};

export default SubsystemEditPage;
