import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list, register, remove, update, getByCompany, getByRep, getByStudent, UpdateObject} from "api/agreements.api";
import { Agreement } from "components/Agreements/types";
import { initialValues } from "../../components/Agreements/types";
export interface AgreementSlice {
  agreements: Agreement[];
  agreement: Agreement;
  status: 'empty' | 'fulfilled' | 'error' | 'updating';
  inactiveAgreements: Agreement[];
  inactiveListStatus: 'empty' | 'fulfilled' | 'error' | 'updating';
  userRole: number;
}

const initialState: AgreementSlice = {
  agreements: [],
  agreement: initialValues,
  status: 'empty',
  inactiveAgreements: [],
  inactiveListStatus: 'empty',
  userRole: 0,
};

export const doRegister = createAsyncThunk(
  "agreements/register",
  async (WorkCenterPayload: Agreement) =>
    register(WorkCenterPayload).then((res) => res)
);

export const doList = createAsyncThunk("agreements/list", async () =>
  list('notInactive').then((res) => res)
);
export const doListInactive = createAsyncThunk("agreements/inactiveList", async () =>
  list('inactive').then((res) => res)
);
export const doGetByCompany = async (companyId: string) =>
getByCompany(companyId).then((res) => res);

export const doGetByRep = async (representativeId: string) =>
getByRep(representativeId).then((res) => res);

export const doGetByStudent = async (studentId: string) =>
getByStudent(studentId).then((res) => res);


export const doPatch = createAsyncThunk(
  "agreements/update",
  async (AgreementsPayload: UpdateObject) =>
    update(AgreementsPayload).then((res) => res)
);
export const doRemove = createAsyncThunk(
  "agreements/remove",
  async (AgreementsPayload: Agreement) =>
    remove(AgreementsPayload).then((res) => res)
);
export const doClear = createAction('agreement/clear', () => {
  return {
    payload: "clear",
  };
});

const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.agreements.push(action.payload);
    });
    builder.addCase(doRemove.fulfilled, (state, action) => {
      state.status = 'updating';
      const index = state.agreements.findIndex(r => r._id === action.payload._id);
      delete state.agreements[index];
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.agreements = action.payload.agreements;
      state.status = 'fulfilled';
      state.userRole = action.payload.userRole;
    });
    builder.addCase(doListInactive.fulfilled, (state, action) => {
      state.inactiveAgreements = action.payload.agreements;
      state.inactiveListStatus = 'fulfilled';
      state.userRole = action.payload.userRole;
    });
    builder.addCase(doPatch.fulfilled, (state, action) => {
      state.agreement = action.payload;
    });
    builder.addCase(doClear, (state, _action) => {
      state.agreements = [];
      state.inactiveAgreements = [];
      state.status = "empty";
      state.userRole=0;
    });
  },
});

export default agreementSlice.reducer;
