import { Divider, Modal, Table, Alert, Button, TourProps, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import * as S from "./Company.styles";
import { doList } from "store/slices/companySlice";
import { doList as doListReps } from "store/slices/representativeSlice";
import { doGetByCompany as doListAgreementsByCompany } from "store/slices/agreementSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getColumns } from "./utils";
import { CompanyNew } from "./CompanyNew";
import { Company } from "./types";
import { CompanyDetail } from "./CompanyDetail";
import { Agreement } from "../Agreements/types";
import { notificationController } from "controllers/notificationController";
import { AgreementsList } from "components/dashboard/AgreementsList";
import { InfoButton } from "../common/buttons/Button/InfoButton";
import { InfoTour } from "components/common/InfoTour/InfoTour";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { useTourCheck } from "hooks/useTourCheck";
import { remove } from "api/company.api";
import { sendNotificationError } from "utils/Status&ErrorMessage";

const { writeCompanies } = PermissionsToAccess;

export const CompanyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status: repstatus } = useAppSelector((state) => state.representative);
  const { companies, status: companyStateStatus } = useAppSelector(
    (state) => state.company
  );
  const [showNewForm, setShowNewForm] = useState(false);
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const [showNoDeleteModal, setShowNoDeleteModal] = useState(false);
  const [ageementsWithCompany, setAgeementsWithCompany] = useState<
    Agreement[] | undefined
  >(undefined);
  const [selectedRowData, setSelectedRowData] = useState<Company | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [spin, setSpin] = useState<boolean>(true);

  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck("company");
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: "Sección de empresas",
      description:
        "En esta sección podrá registrar a las empresas que participan en Educación Dual y consultar la información de aquellas que ya hayan sido registradas.",
      target: () => ref1.current,
    },
    {
      title: "Nueva empresa",
      description:
        "Para realizar el registro de una empresa nueva deberá presionar el botón “Nueva empresa“ y registrar los datos que se solicitan. Recuerde cuidar la ortografía y tener a la mano los datos completos de la empresa.",
      target: () => ref2.current,
      placement: "left",
    },
    {
      title: "Tabla, filtros y búsqueda",
      description:
        "Para realizar la búsqueda de empresas ya registradas podrá hacer uso de los filtros que aparecen en el encabezado de la tabla.",
      placement: "bottom",
    },
    {
      title: "Aviso",
      description:
        "Recuerde que, si otro plantel registró a la empresa con anterioridad, usted podrá hacer uso de ese registro. Le sugerimos verificar que el representante legal y contacto con el que tratan esté registrado de la manera en que usted lo requiere, en caso de no ser así, podrá agregar al registro de la empresa al representante legal y contacto sin duplicar el registro de la empresa.",
      placement: "bottom",
    },
  ];

  useEffect(() => {
    if (companyStateStatus !== "fulfilled") {
      dispatch(doList());
    }
    if (repstatus !== `fulfilled`) {
      dispatch(doListReps());
    }
  }, [companyStateStatus, dispatch, repstatus]);

  useEffect(() => {
    if (companyStateStatus === "fulfilled") {
      setSpin(false);
    }
  }, [companyStateStatus]);

  const toggle = () => {
    setShowNoDeleteModal(!showNoDeleteModal);
  };

  const validateDeleteCompany = async (companyToDelete: string) => {
    let isOkToDeleteFlag: boolean = true;
    setLoadingDelete(true);
    const agreementsFiltered = await doListAgreementsByCompany(
      companyToDelete
    ).catch((_err) => {
      setShowNoDeleteModal(true);
      setLoadingDelete(false);
      isOkToDeleteFlag = false;
      return [];
    });
    if (
      (agreementsFiltered && agreementsFiltered.length > 0) ||
      !isOkToDeleteFlag
    ) {
      setAgeementsWithCompany(agreementsFiltered);
      setShowNoDeleteModal(true);
      setLoadingDelete(false);
      return false;
    }
    return true;
  };

  const onDeleteCompany = async (data: Company) => {
    setAgeementsWithCompany([]);
    const canDelete = await validateDeleteCompany(data._id as string);
    setLoadingDelete(false);
    if (!canDelete) {
      return false;
    }
    const a = companies.filter((company) => company._id === data._id);
    await remove(a[0])
      .then((_companySaved) => {
        notificationController.success({
          message: "Se eliminó la empresa correctamente",
        });
      })
      .catch((error) => {
        sendNotificationError({ error });
        setShowNoDeleteModal(true);
      });
    setLoadingDelete(false);
    dispatch(doList());
  };

  const columns = getColumns(onDeleteCompany, loadingDelete);

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="company-list"
          title={
            <>
              <span ref={ref1}>
                Empresas <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          extra={
            CheckAccess(writeCompanies) && (
              <Button
                style={{ marginLeft: 20 }}
                ref={ref2}
                type="primary"
                onClick={() => {
                  setShowNewForm(true);
                }}
              >
                Nueva empresa
              </Button>
            )
          }
          padding="1.25rem"
        >
          <Divider />
          <Spin spinning={spin} tip="Cargando..." size="large">
            <Table
              dataSource={companies}
              columns={
                CheckAccess(writeCompanies)
                  ? columns
                  : columns.filter((col) => col.title !== "Acción")
              }
              size="small"
              rowKey="_id"
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (_event) => {
                    setSelectedRowData(record);
                    setShowDetailDrawer(true);
                  },
                };
              }}
            />
          </Spin>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block="nearest"
          module="company"
          isFirstTime={isFirstTime}
        />
        <CompanyDetail
          onClose={() => {
            setShowDetailDrawer(false);
          }}
          selectedRow={selectedRowData}
          open={showDetailDrawer}
        />
        <CompanyNew
          onClose={() => {
            setShowNewForm(false);
          }}
          open={showNewForm}
        />
      </S.Wrapper>
      <Modal
        title="No se puede eliminar la empresa"
        open={showNoDeleteModal}
        onOk={() => toggle()}
        onCancel={() => toggle()}
        destroyOnClose
      >
        <p>
          No se puede eliminar una empresa que tiene expedientes asignados. Por
          favor cambia los expedientes antes de eliminar la empresa
        </p>
        <div>
          <Divider orientation="left">
            Estudiantes con expediente con la empresa
          </Divider>
          {!ageementsWithCompany || ageementsWithCompany?.length <= 0 ? (
            <Alert
              message="Advertencia"
              description="Esta empresa no se puede eliminar debido a que tiene expediente con estudiantes de otro plantel o subsitema"
              type="warning"
              showIcon
            />
          ) : (
            <AgreementsList agreements={ageementsWithCompany}></AgreementsList>
          )}
        </div>
      </Modal>
    </>
  );
};
