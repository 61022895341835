import { PageTitle } from "components/common/PageTitle/PageTitle";
import { StudentNew } from "components/students/StudentNew/StudentNew";
import React from "react";

const StudentNewPage: React.FC = () => {
    return (
        <>
            <PageTitle>{`Nuevo Alumno`}</PageTitle>
            <StudentNew />
        </>
    )
}

export default StudentNewPage;