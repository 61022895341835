
export const requiredFields = [{
  id: `studentId`,
  label: `Alumno`
},{
  id: `workCenterId`,
  label: `Plantel`
},{
  id: `companyId`,
  label: `Empresa`
},{
  id: `representativeId`,
  label: `Contacto de la empresa`
},{
  id: `startDate`,
  label: `Fecha de Inicio`
},{
  id: `endDate`,
  label: `Fecha de Finalización`
},{
  id: `learningAgreementFileId`,
  label: `Convenio de Aprendizaje`
},{
  id: `colaborationAgreementFileId`,
  label: `Convenio de Colaboración`
},{
  id: `tutorIdFileId`,
  label: `Identificación del padre o tutor vigente`
},{
  id: `tutorAuthLetterFileId`,
  label: `Carta de autorización del padre o tutor`
},{
  id: `insuranceFileId`,
  label: `Seguro facultativo`
},{
  id: `rotationPlanFileId`,
  label: `Plan de Rotación`
},{
  id: `learningPositionsFieldId`,
  label: `Puestos de Aprendizaje`
},{
  id: `matrixFieldId`,
  label: `Matriz de correspondencia`
},{
  id: `principalFileId`,
  label: `Nombramiento del director`
},{
  id: `principal`,
  label: `Encargado(a) de la Dirección del Plantel`
},{
  id: `principalPosition`,
  label: `Puesto del director(a) firmante`
},{
  id: `rotationTimes`,
  label: `Número de puestos en los que rotará el estudiante`
},{
  id: `rotationPositions`,
  label: `Puestos en los que rotará el estudiante, Ingrese un puesto por linea`
},{
  id: `hoursPerWeek`,
  label: `Horas de trabajo a la semana`
},{
  id: 'legalRepresentativeId',
  label: 'Representante legal de la empresa'
}
]

export const requiredAdultFields = () => {
  const fields = requiredFields
    .filter((f) => f.id !== `tutorAuthLetterFileId`)
    .filter((f) => f.id !== `tutorIdFileId`)
  fields.push({
    id: `tutorIdFileId`,
    label: `Identificación del estudiante mayor de edad`
  })
  return fields;
}

