import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  me,
  ResetPasswordRequest,
  login,
  LoginRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from 'api/auth.api';
import { setUser, doClear as clearUser } from 'store/slices/userSlice';
import { doClear as clearEmployees } from 'store/slices/employeeSlice';
import { doClear as clearAgreements } from 'store/slices/agreementSlice';
import { doClear as clearCompany } from 'store/slices/companySlice';
import { doClear as clearConfig } from 'store/slices/configSlice';
import { doClear as clearRepresentatives } from 'store/slices/representativeSlice';
import { doClear as clearStatistics } from 'store/slices/statisticsSlice';
import { doClear as clearStudents } from 'store/slices/studentSlice';
import { doClear as clearWorkcenters } from 'store/slices/workCenterSlice';
import { deleteToken, deleteUser, persistToken, readToken } from 'services/localStorage.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    dispatch(setUser(res));
    persistToken(res.token);

    return res.token;
  }),
);

export const doValidateConfirmToken = createAsyncThunk('auth/doValidate', async (payload, { dispatch }) =>{
  const currentUser = await me();
  dispatch(setUser(currentUser));
})

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(clearUser());
  dispatch(clearEmployees());
  dispatch(clearAgreements());
  dispatch(clearCompany());
  dispatch(clearConfig());
  dispatch(clearRepresentatives());
  dispatch(clearStatistics());
  dispatch(clearStudents());
  dispatch(clearWorkcenters());
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
