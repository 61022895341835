import React, { useEffect, useState } from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList as WorkcentersList } from "store/slices/workCenterSlice";
import { WorkCenter } from "components/workcenters/types";
import { Alert } from "antd";
import * as Sentry from "@sentry/react";
import { Card } from "components/common/Card/Card.styles";
import { WORKCENTERS_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { WorkcenterEdit } from "components/workcenters/WorkcenterEdit";

const WorkcenterEditPage: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workcenters, status: workcentersStateStatus } = useAppSelector((state) => state.workcenter);
  const [workcenter, setWorkcenter] = useState<WorkCenter | null>();

  useEffect(() => {
    if (workcentersStateStatus !== "fulfilled") {
      dispatch(WorkcentersList());
    }
    if (workcenters.length > 0 && params.id) {
      const currentWorkcenter: WorkCenter | undefined = workcenters
        .filter((c) => c && c._id === params.id)
        .at(0);
      if (currentWorkcenter) {
        setWorkcenter(currentWorkcenter);
      } else {
        Sentry.captureMessage("Not able to find a workcenter to edit");
      }
    }
  }, [params, workcenters, workcentersStateStatus, dispatch]);

  return (
    <>
      <PageTitle>{`Editar plantel`}</PageTitle>
      {workcentersStateStatus === `empty` && (
        <Alert type="info" message={`Cargando información del plantel...`} />
      )}
      {workcentersStateStatus === `updating` && (
        <Alert
          type="info"
          message={`Actualizando información del plantel...`}
        />
      )}
      {workcentersStateStatus === `fulfilled` && workcenter === null && (
        <Alert type="error" message={`Error al cargar el plantel`} />
      )}
      {workcentersStateStatus === `fulfilled` && workcenter && (
        <Card
          $autoHeight={true}
          $padding={`2`}
          id="edit-Workcenter"
          title={
            <>
              <CloseButton
                onClick={() => {
                  navigate(WORKCENTERS_PATH);
                }}
              ></CloseButton>
              <span>&nbsp; {`Editar plantel ${workcenter.name}`}</span>
            </>
          }
        >
          <WorkcenterEdit workcenter={workcenter} />
        </Card>
      )}
    </>
  );
};

export default WorkcenterEditPage;
