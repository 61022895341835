import { WorkCenter } from "components/workcenters/types";

export type ApprovementRoleType = { name: string; sequence: number; };
export const Tourmodules = [
    "dashboard",
    "followAgreement",
    "agreement",
    "downAgreement",
    "student",
    "company",
  ];
export const modules = [
    "dashboard",
    "user",
    "agreement",
    "student",
    "company",
    "workcenter",
    "subsystem",
    "logout",
    "export",
    "approvedAgreement"
  ];
export const PermissionModulesRelation = {
    user: 0,
    agreement: 1,
    student: 2,
    company: 3,
    workcenter: 4,
    subsystem: 5,
    export: 6
}
export const AccessLvls = {
    noAccess: 0,
    readOnly: 1,
    readWrite: 2,
}
export type PermissionsType = { module: typeof modules[number]; permission: number; };
export type tourCompletionType = { module: typeof Tourmodules[number]; done: boolean; };
export  type Employee = {
    _id?: string,
    name: string,
    phone?: string,
    email?: string,
    status: boolean,
    workcenter?: WorkCenter;
    position?: string;
    subsystem?: string;
    role?: number;
    approvementRole?: ApprovementRoleType;
    permissions?: PermissionsType[];
    tourCompletion?: tourCompletionType[];
    lastLogin?: Date | string;
}

export type LoginReportType = {
  name: string,
  email?: string,
  WorkcenterName?: string,
  subsystem?: string;
  hasPassword: boolean,
  lastLogin?: Date | string;
  created_at: Date | string;
}

export const permissionsDefault : PermissionsType[] = [
    {module: "user", permission: 1},
    {module: "agreement", permission: 2},
    {module: "student", permission: 2},
    {module: "company", permission: 2},
    {module: "workcenter", permission: 1},
    {module: "subsystem", permission: 0},
    {module: "export", permission: 1},
  ];

export const initialValues: Employee = {
    name: '',
    phone: '',
    email: '',
    status: false,
    role: 0,
}