import { Card, Spin, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doListAgreementsByEducativeLevel } from 'store/slices/statisticsSlice';
import { useEffect, useState } from 'react';
import { DownloadTableButton } from 'components/common/buttons/Button/DownloadTableButton';
import { ColumnsType } from 'antd/es/table';

type AgreementsTableDataT = {
  educativeLevel: string;
  activos?: number;
  graduados?: number;
  bajas?: number;
  acumulado?: number;
};

type AgreementsDatum = {
  educativeLevel: string;
  ACTIVO?: number;
  INACTIVO?: number;
  'PENDIENTE DE REVISION'?: number;
  BORRADOR?: number;
};

type GetTotal = {
  agreementsData: AgreementsDatum[];
  condition?: string;
};

const getTotal = ({ agreementsData, condition = undefined }: GetTotal) => {
  if (condition === undefined)
    return agreementsData.reduce((acc, curr) => {
      for (const key in curr) {
        if (
          Object.prototype.hasOwnProperty.call(curr, key) &&
          typeof curr[key as keyof typeof curr] === 'number'
        ) {
          acc += curr[key as keyof typeof curr] as number;
        }
      }
      return acc;
    }, 0);

  return agreementsData.reduce((acc, curr) => {
    for (const key in curr) {
      if (
        Object.prototype.hasOwnProperty.call(curr, key) &&
        typeof curr[key as keyof typeof curr] === 'number' &&
        key === condition
      ) {
        acc += curr[key as keyof typeof curr] as number;
      }
    }
    return acc;
  }, 0);
};

export const AgreementsStatisticsTable = () => {
  const [spin, setSpin] = useState<boolean>(true);
  const [agreementsStatisticsData, setAgreementsStatisticsData] =
    useState<AgreementsTableDataT[]>();
  const dispatch = useAppDispatch();
  const {
    agreementsByEducativeLevel,
    agreementsByEducativeLevelStatus: agreementsStatus,
  } = useAppSelector((state) => state.statistics);

  useEffect(() => {
    if (agreementsStatus !== 'fulfilled') {
      dispatch(doListAgreementsByEducativeLevel());
    }
  }, [dispatch, agreementsByEducativeLevel, agreementsStatus]);

  useEffect(() => {
    if (
      agreementsByEducativeLevel !== undefined &&
      agreementsByEducativeLevel.length > 0
    ) {
      setSpin(true);
      const agreementsStatistics = agreementsByEducativeLevel.map((data) => {
        const { educativeLevel, agreements: agreements } = data;
        const statusLenghts = agreements.map((agrData) => {
          const { status, agreements } = agrData;
          return { status, length: agreements.length };
        });
        return { educativeLevel, statusLenghts };
      });
      const agreementsData: Array<{
        educativeLevel: string;
        ACTIVO?: number;
        INACTIVO?: number;
        BORRADOR?: number;
        'PENDIENTE DE REVISION'?: number;
      }> = agreementsStatistics.map((agr) => {
        const statistics: { [key: string]: number } = agr.statusLenghts.reduce(
          (acc: { [key: string]: number }, statusData) => {
            acc[statusData.status] = statusData.length;
            return acc;
          },
          {}
        );
        return { educativeLevel: agr.educativeLevel, ...statistics };
      });
      const educativeLevelData = agreementsData.map((data) => {
        const {
          educativeLevel,
          ACTIVO: ACTIVE,
          INACTIVO: INACTIVE,
          'PENDIENTE DE REVISION': PENDING,
          BORRADOR: DRAFT,
        } = data;
        const TOTAL =
          (ACTIVE ?? 0) + (INACTIVE ?? 0) + (PENDING ?? 0) + (DRAFT ?? 0);
        return {
          educativeLevel,
          total: TOTAL ?? 0,
          graduated: INACTIVE ?? 0,
          active: (ACTIVE ?? 0) + (PENDING ?? 0) + (DRAFT ?? 0),
        };
      });

      const totalInactive: number = getTotal({
        agreementsData,
        condition: 'INACTIVO',
      });
      const totalActive: number = getTotal({
        agreementsData,
        condition: 'ACTIVO',
      });
      const totalPending: number = getTotal({
        agreementsData,
        condition: 'PENDIENTE DE REVISION',
      });
      const totalDrafts: number = getTotal({
        agreementsData,
        condition: 'BORRADOR',
      });
      const agreementsTableData = [
        ...educativeLevelData,
        {
          educativeLevel: 'Total',
          total:
            totalActive + totalInactive + totalPending + totalDrafts,
          graduated: totalInactive,
          active: totalActive + totalPending + totalDrafts,
        },
      ];
      setAgreementsStatisticsData(agreementsTableData);
      setSpin(false);
    }
  }, [agreementsByEducativeLevel]);

  const agreementsStatisticsColumns: ColumnsType<AgreementsTableDataT> = [
    {
      title: 'Nivel Educativo',
      dataIndex: 'educativeLevel',
      key: 'educativeLevel',
    },
    {
      title: 'Acumulado',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
    },
    {
      title: 'Graduados',
      dataIndex: 'graduated',
      key: 'graduated',
      align: 'right',
    },
    {
      title: 'Activos',
      dataIndex: 'active',
      key: 'active ',
      align: 'right',
    },
  ];

  return (
    <Card
      style={{ width: '100%', height: '100%' }}
      title='Estudiantes de educación dual'
      bordered={false}
      size='small'
      extra={
        <DownloadTableButton
          columns={agreementsStatisticsColumns}
          data={agreementsStatisticsData}
        />
      }
    >
      <Spin spinning={spin} delay={400} tip='Cargando...' size='large'>
        <Table
          style={{ width: '100%', height: '100%' }}
          scroll={{ x: true }}
          columns={agreementsStatisticsColumns}
          dataSource={agreementsStatisticsData}
          pagination={false}
          rowClassName={(record) =>
            record.educativeLevel === 'Total' ? 'bold-row' : ''
          }
        />
      </Spin>
    </Card>
  );
};
