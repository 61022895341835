import { Tourmodules } from "components/employees/types";
import { User } from "types";

export const CheckTourCompletion = (module: (typeof Tourmodules)[number], user: User) => {
  if(!user?.tourCompletion){
    return false;
  }
  const tourModule = user.tourCompletion.find((modules) => {
    return modules.module === module;
  })
  if(!tourModule){
    return false;
  }
  return tourModule?.done
};
