import { PageTitle } from "components/common/PageTitle/PageTitle";
import { EmployeeNew } from "components/employees/EmployeeNew";

import React from "react";

const EmployeeNewPage: React.FC = () => {
    return (
        <>
                <PageTitle>{`Nuevo colaborador`}</PageTitle>
                <EmployeeNew  />
        </>
    )
}

export default EmployeeNewPage;