import React from "react";
import { EmployeesList } from "components/employees/EmployeesList/EmployeesList";
import { PageTitle } from "components/common/PageTitle/PageTitle";

const EmployeesPage: React.FC = () => {
  return (
    <>
      <PageTitle>{`Lista de colaboradores`}</PageTitle>
      <EmployeesList />
    </>
  );
};

export default EmployeesPage;
