import {
  Employee,
  initialValues as initEmployee,
} from 'components/employees/types';
import {
  Municipality,
  initialValuesMunicipality as initMunicipality,
} from 'components/municipality/types';

export type WorkCenter = {
  _id?: string;
  name: string;
  nameShort: string;
  principal: Employee;
  keySep?: string; // mts
  subsystemId: string;
  subsystem: string; // typeof subsystems[number];
  subsystemShort: string;
  currentSubsystemPrincipal?: string;
  municipality: Municipality | string | undefined ;
  educativeLevel?: string;
  principalFileId?: string;
};

export const initialValues: WorkCenter = {
  name: '',
  nameShort: '',
  principal: initEmployee,
  subsystemId: '',
  subsystem: '',
  subsystemShort: '',
  currentSubsystemPrincipal: '',
  municipality: initMunicipality,
};
