import React from 'react';
import Chart from 'react-apexcharts';



interface DonutProps {
  series: number[];
  labels : string[];

}

export const DonutChart: React.FC<DonutProps> = ({series, labels }) => {
  return(
    <Chart 
    type="donut"
    series={series}
    height={350}
    options={{
     labels:labels,
     responsive: [{
      breakpoint: 300,
      options: {
        chart: {
          width: 200,
          height:150
        },
        legend: {
          position: 'bottom'
        },
      }
    }],
     plotOptions:{
     pie:{
        donut:{
          size: '65%',
            labels:{
                show:true,
                total:{
                    show:true,
                    showAlways:true,
                    color: '#f90000',
                }
            }
        }
     }
     },
     dataLabels:{
        enabled:true,
     }

    }}
    />
)};
