import React, { useEffect, useState } from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList as employeeDoList } from "store/slices/employeeSlice";
import { Employee } from "components/employees/types";
import { Alert } from "antd";
import * as Sentry from "@sentry/react"
import { EmployeeEdit } from "components/employees/EmployeesEdit";
import { Card } from "components/common/Card/Card.styles";
import { EMPLOYEES_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
// import { doList as workCenterDoList } from "store/slices/workCenterSlice";
// import { WorkCenter } from "components/workcenters/types";

const EmployeeEditPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { employees, status: employeeStateStatus } = useAppSelector((state) => state.employee);
  // const { workcenters, status: workCenterStateStatus } = useAppSelector((state) => state.workcenter);
  const [ employee, setEmployee ] = useState<Employee | null>();
  // const [ employeeWorkCenter, setEmpWorkCenter ] =  useState<WorkCenter | null>();
  useEffect(() => {
    if (employeeStateStatus === 'empty'){
      dispatch(employeeDoList());
    } else if (employeeStateStatus === 'fulfilled' && params.id) {
      const currentEmployee: Employee | undefined = employees.filter((c) => c && c._id === params.id).at(0);
      if ( currentEmployee ) {
        setEmployee(currentEmployee);
      } else {
        Sentry.captureMessage("Not able to find a employee to edit");
      }
    }
  },[params, employees, employeeStateStatus, dispatch, employee]);
    return (
      <>
          <PageTitle>{`Editar Colaborador`}</PageTitle>
          {employeeStateStatus === `empty` && (
            <Alert
              type="info"
              message={`Cargando información del colaborador...`}
            />
          )}
          {employeeStateStatus === `fulfilled` && employee === null && (
            <Alert type="error" message={`Error al cargar el colaborador`} />
          )}
          {employeeStateStatus === `fulfilled` && employee && (
            <Card
              $autoHeight={true}
              $padding={`2`}
              id="edit-employee"
              title={
                <>
                  <CloseButton
                    onClick={() => {
                      navigate(EMPLOYEES_PATH);
                    }}
                  ></CloseButton>
                  <span>&nbsp; {`Editar colaborador ${employee.name}`}</span>
                </>
              }
            >
              <EmployeeEdit employee={employee} />
            </Card>
          )}
      </>
    );
}

export default EmployeeEditPage;