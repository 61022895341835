import { Card, Descriptions, Empty, Row, Space, Spin } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Agreement } from "components/Agreements/types";
import { WorkCenter } from "components/workcenters/types";
import { Student, gradesString, DescritpionProps, HoursByEducativeLevel } from "../types";
import { getEdad, getHoursMinutesString, valueDate } from "hooks/dateTreatment";
import { colorStatus } from "../Student.styles";
import { AgreementsList } from "components/dashboard/AgreementsList";
import { doList as agreementsDoList, doListInactive } from "store/slices/agreementSlice";
import { CommentsStudent } from "./CommentsStudent";
import { CommentsOptions } from "./CommentsOptions";
import { colorCompletedHours } from "components/Agreements/Agreements.styles";
import { Document } from "components/common/Document/Document";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";

type StudentDetailProps = {
  selectedRow: Student;
};

export const StudentDetail = ({ selectedRow }: StudentDetailProps) => {
  const dispatch = useAppDispatch();
  //agreements
  const { agreements, status, inactiveAgreements, inactiveListStatus } = useAppSelector((state) => state.agreement);
  const [activeStudentAgreements, setActiveStudentAgreements] = useState<Agreement[]>();
  const [inactiveStudentAgreements, setInactiveStudentAgreements] = useState<Agreement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLeaveMotive, setShowLeaveMotive] = useState<boolean>(false);
  // document
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  const filterAgreementByStudent = () => {
      setActiveStudentAgreements(
          agreements.filter((agre) => {
              return agre.studentId._id === selectedRow._id;
          })
      );
  };

  const filterInactiveAgreementByStudent = () => {
      setInactiveStudentAgreements(
          inactiveAgreements.filter((agre) => {
              return agre.studentId._id === selectedRow._id;
          })
      );
  };

  useEffect(() => {
      if (
          selectedRow.leaveMotive &&
          (selectedRow.academicStatus === 'Baja temporal' ||
              selectedRow.academicStatus === 'Baja definitiva')
      ) {
          setShowLeaveMotive(true);
      } else {
          setShowLeaveMotive(false);
      }
  }, []);

  useEffect(() => {
      if (status !== 'fulfilled') {
          setIsLoading(true);
          dispatch(agreementsDoList());
      } else {
          filterAgreementByStudent();
          setIsLoading(false);
      }
  }, [dispatch, status, agreements]);

  useEffect(() => {
      if (inactiveListStatus !== 'fulfilled') {
          setIsLoading(true);
          dispatch(doListInactive());
      } else {
          filterInactiveAgreementByStudent();
          setIsLoading(false);
      }
  }, [dispatch, inactiveListStatus, inactiveAgreements]);

  //workcenter data
  const workCenterData = (item: WorkCenter): ReactNode => {
    return (
      <>
        <Descriptions bordered size="small" title="Plantel">
          <Descriptions.Item span={3} key="subsystem" label="Subsistema / IES">
            {item.subsystem ?? "No hay Registro"}
          </Descriptions.Item>
          <Descriptions.Item span={3} key="workName" label="Plantel">
            {item.nameShort ?? ""}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            key="directorSubsystem"
            label="Director(a) general del subsistema / IES"
          >
            {item.currentSubsystemPrincipal ?? ""}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <Descriptions {...DescritpionProps} size='small'>
        <Descriptions.Item key='name' label='Nombre(s)' span={1}>
          {`${selectedRow.name}`}
        </Descriptions.Item>
        <Descriptions.Item key='name' label='Apellido paterno' span={1}>
          {`${selectedRow.paternalLastname ?? ''}`}
        </Descriptions.Item>
        <Descriptions.Item key='name' label='Apellido materno' span={1}>
          {`${selectedRow.maternalLastname ?? ''}`}
        </Descriptions.Item>
        <Descriptions.Item key='status' label='Estado académico'>
          <p style={{ color: colorStatus(selectedRow.academicStatus) }}>
            {selectedRow.academicStatus ?? 'Sin registro'}
          </p>
        </Descriptions.Item>
        <Descriptions.Item key='CURP' label='CURP'>
          {selectedRow.curp}
        </Descriptions.Item>
        <Descriptions.Item key='gender' label='Género'>
          {selectedRow.gender ?? ''}
        </Descriptions.Item>
        <Descriptions.Item key='edad' label='Edad y Fecha de nacimiento'>
          {selectedRow.birthDate
            ? `${getEdad(selectedRow.birthDate)} Años - ${valueDate(
                selectedRow.birthDate,
                false
              )}`
            : 'No ingresada'}
        </Descriptions.Item>
        <Descriptions.Item key='completionDate' label='Fecha de Graduacion'>
          {selectedRow.completionDate
            ? valueDate(selectedRow.completionDate)
            : 'No ingresada'}
        </Descriptions.Item>
        <Descriptions.Item span={3} key='email' label='Correo electrónico'>
          {selectedRow.email}
        </Descriptions.Item>
        <Descriptions.Item key='phone' label='Teléfono'>
          {selectedRow.phone ? selectedRow.phone : 'Sin teléfono Registrado'}
        </Descriptions.Item>
        <Descriptions.Item key='career' label='Carrera'>
          {selectedRow.career}
        </Descriptions.Item>
        <Descriptions.Item key='grade' label='Periodo escolar'>
          {selectedRow.grade} - {gradesString[selectedRow.grade ?? 0]}
        </Descriptions.Item>
        <Descriptions.Item key='tutor' label='Nombre del Responsable'>
          {selectedRow.tutor ?? ''}
        </Descriptions.Item>
        <Descriptions.Item key='completedHours' label='Horas completadas' span={3}>
          {StudentHours(selectedRow.completedHours)}
        </Descriptions.Item>
        {selectedRow.certificateFiles &&
          selectedRow.certificateFiles.length > 0 &&
          selectedRow.certificateFiles.map((certificate) => (
            <Descriptions.Item
              span={3}
              key='certificateFileId'
              label={`Certificado de finalización (${certificate.educativeLevel})`}
            >
              {certificate ? (
                <ShowDocumentBtn
                  label={certificate.educativeLevel}
                  onClick={() => {
                    setPdfViewerId(certificate.certificateFileId);
                    setShowDocument(true);
                  }}
                />
              ) : (
                <>No se ha cargado el Certificado de finalización</>
              )}
            </Descriptions.Item>
          ))}
        {showLeaveMotive && (
          <Descriptions.Item key='leaveMotive' label='Motivo de baja'>
            {selectedRow.leaveMotive ?? ''}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Document
        fileId={pdfViewerId}
        open={showDocument}
        onCancel={() => setShowDocument(false)}
        onOk={() => setShowDocument(false)}
      />
      <br />
      {selectedRow.workcenter
        ? workCenterData(selectedRow.workcenter)
        : 'Contacta al Administrador'}
      <br />
      <Card title={`Expedientes activos del alumno`}>
        <Spin spinning={isLoading}>
          {activeStudentAgreements && activeStudentAgreements.length > 0 ? (
            <AgreementsList
              agreements={activeStudentAgreements}
            ></AgreementsList>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
          )}
        </Spin>
      </Card>

      <Card title={`Expedientes inactivos del alumno`}>
        <Spin spinning={isLoading}>
          {inactiveStudentAgreements && inactiveStudentAgreements.length > 0 ? (
            <AgreementsList
              agreements={inactiveStudentAgreements}
            ></AgreementsList>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
          )}
        </Spin>
      </Card>
      <br />
      <Descriptions
        bordered
        size='small'
        title='Comentarios'
        extra={
          <CommentsOptions selectedRow={selectedRow} visibleRegresar={false} />
        }
      ></Descriptions>
      {selectedRow.comments && <CommentsStudent selectedRow={selectedRow} />}
    </>
  );
};

export const StudentInfo = ({ selectedRow }: StudentDetailProps) => {
  return (
    <>
      <Descriptions {...DescritpionProps} size='small' column={3}>
        <Descriptions.Item key='name' label='Nombre del estudiante' span={3}>
          {`${selectedRow.name} ${selectedRow.paternalLastname} ${selectedRow.maternalLastname}`}
        </Descriptions.Item>
        <Descriptions.Item key='name' label='Apellido paterno' span={3}>
          {`${selectedRow.paternalLastname ?? ''}`}
        </Descriptions.Item>
        <Descriptions.Item key='name' label='Apellido materno' span={3}>
          {`${selectedRow.maternalLastname ?? ''}`}
        </Descriptions.Item>
        <Descriptions.Item key='status' label='Estado académico' span={3}>
          <p style={{ color: colorStatus(selectedRow.academicStatus) }}>
            {selectedRow.academicStatus ?? 'Sin registro'}
          </p>
        </Descriptions.Item>
        <Descriptions.Item key='CURP' label='CURP' span={3}>
          {selectedRow.curp}
        </Descriptions.Item>
        <Descriptions.Item key='gender' label='Género' span={3}>
          {selectedRow.gender ?? ''}
        </Descriptions.Item>
        <Descriptions.Item
          key='edad'
          label='Edad y Fecha de nacimiento'
          span={3}
        >
          {selectedRow.birthDate
            ? `${getEdad(selectedRow.birthDate)} Años - ${valueDate(
                selectedRow.birthDate,
                false
              )}`
            : 'No ingresada'}
        </Descriptions.Item>
        <Descriptions.Item span={3} key='email' label='Correo electrónico'>
          {selectedRow.email}
        </Descriptions.Item>
        <Descriptions.Item key='phone' label='Teléfono' span={3}>
          {selectedRow.phone ? selectedRow.phone : 'Sin teléfono Registrado'}
        </Descriptions.Item>
        <Descriptions.Item key='career' label='Carrera' span={3}>
          {selectedRow.career}
        </Descriptions.Item>
        <Descriptions.Item key='grade' label='Periodo escolar' span={3}>
          {selectedRow.grade} - {gradesString[selectedRow.grade ?? 0]}
        </Descriptions.Item>
        <Descriptions.Item key='tutor' label='Nombre del Responsable' span={3}>
          {selectedRow.tutor ?? ''}
        </Descriptions.Item>
        <Descriptions.Item
          key='completedHours'
          label='Horas completadas'
          span={3}
        >
          {StudentHours(selectedRow.completedHours)}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions bordered size='small' title='Plantel'>
        <Descriptions.Item
          span={3}
          key='subsystem'
          label='Subsistema del plantel'
        >
          {(selectedRow.workcenter as WorkCenter).subsystem ??
            'No hay Registro'}
        </Descriptions.Item>
        <Descriptions.Item span={3} key='workName' label='Plantel actual'>
          {(selectedRow.workcenter as WorkCenter).nameShort ?? ''}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          key='directorSubsystem'
          label='Director(a) general del subsistema / IES'
        >
          {(selectedRow.workcenter as WorkCenter).currentSubsystemPrincipal ??
            ''}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export const StudentHours = (
  StudentHoursObject: HoursByEducativeLevel[] | undefined
) =>
  StudentHoursObject
    ? StudentHoursObject.map((item) => (
        <Row>
          <Space>
            {item.educativeLevel === 'SUPERIOR' ? 'ES' : 'EMS'}
            {'>'}
            <p style={colorCompletedHours(item.completedHours ?? 0)}>
              {item.completedHours
                ? getHoursMinutesString(item.completedHours)
                : '-'}
            </p>
          </Space>
        </Row>
      ))
    : 'Sin registro';
  