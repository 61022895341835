import { Button, Space } from "antd";
import { Student } from "../types";
import { Link } from "react-router-dom";
import { STUDENTS_PATH } from "components/router/AppRouter";

type OptionProps = {
  rowSelected: Student;
};
export const OptionsDrawerBar = ({ rowSelected }: OptionProps) => {
  return (
    <>
      <Space>
        <Link to={`${STUDENTS_PATH}/edit/${rowSelected._id}`}>
          <Button type="primary">Editar</Button>
        </Link>
      </Space>
    </>
  );
};
