import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subsystem, educativeLevelOptions, initialValues } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "hooks/reduxHooks";
import { Input, Row, Col, Form, Select } from "antd";
import { doRegister } from "store/slices/subsystemSlice";
import { notificationController } from "controllers/notificationController";
import * as S from "./subsystem.styles";
import { SUBSYSTEMS_PATH } from "components/router/AppRouter";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { SendCustomErrorMessage } from "utils/Status&ErrorMessage";

export const SubsystemNewForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  //Select

  const handleSubmit = (values: Subsystem) => {
    setLoading(true);
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Se agregó el subsistema correctamente",
        });
        form.resetFields();
        navigate(SUBSYSTEMS_PATH);
      })
      .catch((_err) => {
        SendCustomErrorMessage({error: _err});
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.SmallCard
          id="employees-new"
          title={
            <>
              <CloseButton
                onClick={() => {
                  navigate(SUBSYSTEMS_PATH);
                }}
              ></CloseButton>
              <span>&nbsp; {`Nuevo Subsistema  / IES (Instituto de Educación Superior)`}</span>
            </>
          }
          padding="3rem"
        >
          <BaseForm
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initialValues}
          >
            <Row>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="nameShort"
                  label={`Nombre Corto`}
                  rules={[
                    { required: true, message: "Ingresa un nombre corto para el subsistema" },
                  ]}
                >
                  <Input
                    placeholder="CBTIS
"
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}  style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="name"
                  label={`Nombre Completo`}
                  rules={[
                    { required: true, message: "Ingresa el nombre completo del subsistema" },
                  ]}
                >
                  <Input placeholder="CENTRO DE BACHILLERATO TECNOLÓGICO INDUSTRIAL Y DE SERVICIOS" />
                </BaseForm.Item>
              </Col>
              <Col>
                <BaseForm.Item
                  name='educativeLevel'
                  label={`Nivel educativo`}
                  rules={[{ required: true, message: 'Selecciona el nivel educativo' }]}
                >
                  <Select
                    style={{ width: 200 }}
                    placeholder='Selecciona un nivel educativo'
                    options={educativeLevelOptions}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="principal"
                  label={`Director(a) general del subsistema / Rector(a) de la IES`}
                  required
                  rules={[
                    { required: true, message: "Ingresa el nombre del director" },
                  ]}
                >
                  <Input />
                </BaseForm.Item>
              </Col>
            </Row>

            <BaseForm.Item noStyle>
              <S.SubmitButton
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </S.SmallCard>
      </S.Wrapper>
    </>
  );
};
