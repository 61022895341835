import { Button, Space } from "antd";
import { Subsystem } from "./types";
import { Link } from "react-router-dom";

type OptionProps = {
  rowSelected: Subsystem;
};
export const OptionsDrawerBar = ({ rowSelected }: OptionProps) => {

  return (
    <>
      <Space>
          <Link to={`/subsistemas/editar/${rowSelected._id}`}>
            <Button type="primary">Editar</Button>
          </Link>
      </Space>
    </>
  );
};
