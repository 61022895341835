import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as S from "./SiderMenu.styles";
import { sidebarNavigation, SidebarNavigationItem } from "../sidebarNavigation";
import { Menu } from "antd";
import { CheckAccess } from "checkAccess/CheckAccess";
import { AccessLvls } from "components/employees/types";
import {PermissionsToAccess} from "checkAccess/ConstPermissions"

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current
    ),
  []
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const location = useLocation();

  const currentMenuItem = sidebarNavFlat.find(
    ({ url }) => url === location.pathname
  );
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname)
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const getPermissionObj = (key: string) => {
    switch (key) {
      case "dashboard":
        return { module: "dashboard", permission: AccessLvls.readOnly };
      case "employees":
        return PermissionsToAccess.readEmployees;
      case "agreements":
        return PermissionsToAccess.readAgreements;
      case "newAgreement":
        return PermissionsToAccess.writeAgreements;
      case "agreementsFollow":
        return PermissionsToAccess.readAgreements;
      case "agreementsList":
        return PermissionsToAccess.readAgreements;
      case "agreementsDown":
          return PermissionsToAccess.readAgreements;
      case "alumnos":
        return PermissionsToAccess.readStudents;
      case "empresas":
        return  PermissionsToAccess.readCompanies;
      case "workcenters":
        return  PermissionsToAccess.readWorkcenters;
      case "subsystems":
        return  PermissionsToAccess.readSubsystems;
      default:
        return { module: "logout", permission: AccessLvls.noAccess };
    }
  }

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
    >
      {sidebarNavigation.map((nav) => {
        if(!CheckAccess(getPermissionObj(nav.key))){
          return <></>
        }
        return nav.children && nav.children.length > 0 ? (
          <Menu.SubMenu
            key={nav.key}
            title={nav.title}
            icon={nav.icon}
            onTitleClick={() => setCollapsed(false)}
            popupClassName="d-none"
          >
            {nav.children.map((childNav) => {
               if(!CheckAccess(getPermissionObj(childNav.key))){
                return <></>
              }
              return(
              <Menu.Item key={childNav.key} title="" icon={childNav.icon}>
                <Link to={childNav.url || ""}>{childNav.title}</Link>
              </Menu.Item>
            )})}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={nav.key} title="" icon={nav.icon}>
            <Link to={nav.url || ""}>{nav.title}</Link>
          </Menu.Item>
        );
      })}
    </S.Menu>
  );
};

export default SiderMenu;
