import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list } from "api/company.api";
import { Company } from "../../components/companies/types";

export interface CompanySlice {
  companies: Company[];
  status: 'empty' | 'fulfilled' | 'error' | 'updating'
}

const initialState: CompanySlice = {
  companies: [],
  status: 'empty'
};

export const doList = createAsyncThunk("company/list", async () =>
  list().then((res) => res)
);

export const doClear = createAction('company/clear', () => {
  return {
    payload: "clear",
  };
});
  
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doList.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doClear, (state, _action) => {
      state.companies = [];
      state.status = "empty";
    });
  },
});

export default companySlice.reducer;
