import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doResetPassword } from 'store/slices/authSlice';
import { notificationController } from 'controllers/notificationController';

interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: '',
};

export const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    dispatch(doResetPassword(values))
      .unwrap()
      .then((response) => {
        if(response.email_status) {
          setLoading(false);
          notificationController.success({
            message: "Correo enviado, revisa tu bandeja de entrada",
          });
          navigate('/auth/login');
        } else {
          setLoading(false);
          notificationController.error({message: 'Error al enviar correo, por favor intenta más tarde'})
        }
      })
      .catch((err) => {
        if (err.message && err.message.includes("status code 404")) {
          notificationController.error({
            message: "Error: usuario no encontrado",
          });
        } else {
          notificationController.error({ message: err.message });
        }
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {`Regresar`}
        </Auth.BackWrapper>
        <Auth.FormTitle>{`Recuperar contraseña`}</Auth.FormTitle>
        <S.Description>{`Ingresa tu dirección de email, te enviaremos instrucciones para ingresar una nueva contraseña`}</S.Description>
        <Auth.FormItem
          name="email"
          label={`Email`}
          rules={[{ required: true, message: `Campo obligatorio`}]}
        >
          <Auth.FormInput placeholder={`email`} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {`Enviando correo`}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
