import { Descriptions, List, Button, Divider, Row, Col, Typography, Progress, Tooltip, Collapse, Modal } from 'antd';
import { ReactNode, useEffect, useState } from "react";
import { DescritpionProps } from "./types";
import { WorkCenter } from "components/workcenters/types";
import { getHoursMinutesString, valueDate } from "hooks/dateTreatment";
import { Agreement } from "./types";
import { AgreementStudent } from "./details/AgreementStudent";
import { CompnayAgremnt } from "./details/CompnayAgr";
import { Document } from "components/common/Document/Document";
import { colorCompletedHours, colorStatus } from "./Agreements.styles";
import { ComentsAgreement } from "./details/ComentsAgreement";
import { CommentsOptions } from "./CommentsOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Employee } from "components/employees/types";
import { IsAdultStudent, estimateTotalHours } from './utils/utils';
import { InfoCircle } from 'components/common/InfoCircle/InfoCircle';
import RequiredFieldsCheckList from './RequiredFieldsCheckList';
import { CheckCircleTwoTone } from '@ant-design/icons'
import { useAppSelector } from 'hooks/reduxHooks';

type AgreementDetailProps = {
  selectedRow: Agreement;
  closeModalDetails: ((arg0: boolean)=>void) | boolean;
};

const { Text } = Typography;

type FileListItem = {
  id?: string,
  title: string,
  style?: string,
}

export const AgreementDetail = ({
  selectedRow,
  closeModalDetails,
}: AgreementDetailProps) => {
  const [showDocument, setShowDocument] = useState(false);
  const [visibleLaptopFields, setVisibleLaptopFields] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  // actual hours estimate
  const [hoursEstimate, setHoursEstimate] = useState(0);
  const [hoursProjection, setHoursProjection] = useState(0);
  const [showTodayEstimate, setShowTodayEstimate] = useState(false);
  const [showProjectionEstimate, setShowProjectionEstimate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const approvalLabels = () => {
    switch (selectedRow.status) {
      case 'RECHAZADO':
      case 'BORRADOR':
        return (
          <>
            <p>Vinculadores autorizados: En proceso.</p>
            <Divider style={{ margin: '5px 0px' }} />
            <p>Subsistema: No iniciado.</p>
            <Divider style={{ margin: '5px 0px' }} />
            <p>Coordinación de educación dual: No iniciado.</p>
          </>
        );
      case 'PENDIENTE DE REVISION':
        if (selectedRow.approvalStep?.sequence === 2) {
          return (
            <>
              <Row justify='space-between'>
                <p>Vinculadores autorizados:</p>
                <CheckCircleTwoTone />
              </Row>
              <Divider style={{ margin: '5px 0px' }} />
              <Row justify='space-between'>
                <p>Subsistema:</p>
                <CheckCircleTwoTone />
              </Row>
              <Divider style={{ margin: '5px 0px' }} />
              <Row justify='space-between'>
                <p>Coordinación de educación dual:</p>
                <p>En proceso.</p>
              </Row>
            </>
          );
        }
        return (
          <>
            <Row justify='space-between'>
              <p>Vinculadores autorizados:</p>
              <CheckCircleTwoTone />
            </Row>
            <Divider style={{ margin: '5px 0px' }} />
            <Row justify='space-between'>
              <p>Subsistema:</p>
              <p>En proceso.</p>
            </Row>
            <Divider style={{ margin: '5px 0px' }} />
            <Row justify='space-between'>
              <p>Coordinación de educación dual:</p>
              <p>No iniciado.</p>
            </Row>
          </>
        );
        case 'ACTIVO':
          return (
            <>
              <Row justify='space-between'>
                <p>Vinculadores autorizados:</p>
                <CheckCircleTwoTone />
              </Row>
              <Divider style={{ margin: '5px 0px' }} />
              <Row justify='space-between'>
                <p>Subsistema:</p>
                <CheckCircleTwoTone />
              </Row>
              <Divider style={{ margin: '5px 0px' }} />
              <Row justify='space-between'>
                <p>Coordinación de educación dual:</p>
                <CheckCircleTwoTone />
              </Row>
            </>
          );
      default:
        <p>No se encontraron datos</p>;
        break;
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  //progress bar
   const [percent, setPercent] = useState<number>(0);
   const [fieldsForProgress, setFieldsForProgress] = useState<Agreement>({
      sequence: selectedRow.sequence,
      studentId: selectedRow.studentId,
      workCenterId: selectedRow.workCenterId,
      companyId: selectedRow.companyId,
      representativeId: selectedRow.representativeId,
      legalRepresentativeId: selectedRow.legalRepresentativeId,
      startDate: selectedRow.startDate,
      endDate: selectedRow.endDate,
      status: selectedRow.status,
      learningAgreementFileId: selectedRow.learningAgreementFileId,
      colaborationAgreementFileId: selectedRow.colaborationAgreementFileId,
      tutorIdFileId: selectedRow.tutorIdFileId,
      tutorAuthLetterFileId: selectedRow.tutorAuthLetterFileId,
      insuranceFileId: selectedRow.insuranceFileId,
      rotationPlanFileId: selectedRow.rotationPlanFileId,
      learningPositionsFieldId: selectedRow.learningPositionsFieldId,
      matrixFieldId: selectedRow.matrixFieldId,
      principalFileId: selectedRow.principalFileId,
      principal: selectedRow.principal,
      principalPosition: selectedRow.principalPosition,
      rotationTimes: selectedRow.rotationTimes,
      rotationPositions: selectedRow.rotationPositions,
      hoursPerWeek: selectedRow.hoursPerWeek,
   });

   // label and display if adult or not
   const [INETutorEstudiante, setINELabel] = useState("");
   const [letterDisplay, setLetterDisplay]  = useState("");

   const ifAdultChangeFiles = () => {
     let ineLabel = "INE del padre o tutor vigente";
     let display = "";

     if (IsAdultStudent(selectedRow.studentId)) {
       display = "none";
       display = selectedRow.tutorAuthLetterFileId ? "" : "none";
       ineLabel = "INE del estudiante mayor de edad";
       setFieldsForProgress({
         ...fieldsForProgress,
         tutorAuthLetterFileId:
           selectedRow.tutorAuthLetterFileId ?? "no aplica",
       });
     }
     setINELabel(ineLabel);
     setLetterDisplay(display);
   };

   const getActualHoursEstimate = () => {
    if(!selectedRow.hoursPerWeek || !selectedRow.startDate) {
      return ;
    }
    const today = new Date();
    today.setHours(0,0,0)
    const hoursMin = estimateTotalHours(selectedRow.startDate, today, selectedRow.hoursPerWeek);
    if(hoursMin !== false) {
      setHoursEstimate(hoursMin);
    }
   }
   const getHoursProjection = () => {
    if(!selectedRow.hoursPerWeek || !selectedRow.startDate || !selectedRow.endDate ) {
      return ;
    }
    const hoursMin = estimateTotalHours(selectedRow.startDate, selectedRow.endDate, selectedRow.hoursPerWeek);
    if(hoursMin !== false) {
      setHoursProjection(hoursMin);
    }
   }

   const handleShowHoursEstimate = () => {
    let showTodayEstimate = false;
    let showProjection = false;
    if(selectedRow.startDate && selectedRow.hoursPerWeek) {
      showTodayEstimate=false;
    }
    if (selectedRow.startDate && selectedRow.hoursPerWeek && selectedRow.endDate) {
      const today = new Date();
      const endDate = new Date(selectedRow.endDate)
      showProjection = true;
      showTodayEstimate = today < endDate;
    } else{
      showTodayEstimate = false;
    }
    setShowTodayEstimate(showTodayEstimate);
    setShowProjectionEstimate(showProjection);
   }

   const showLaptopFields = () => {
    let show = false;
    if (selectedRow.laptopAssignmentFileId || selectedRow.laptopDeliveryDate || selectedRow.laptopSN) {
     show = true;
    }
    setVisibleLaptopFields(show);
   };

   const CheckProgress = ()=>{
    const newProgress = Math.round(
      (Object.values(fieldsForProgress).filter(Boolean).length / Object.keys(fieldsForProgress).length) * 100
    );
    setPercent(newProgress);
   }

   useEffect(() => {
     ifAdultChangeFiles();
     getActualHoursEstimate();
     getHoursProjection();
     showLaptopFields();
     handleShowHoursEstimate();
   }, [selectedRow]);

   useEffect(() => {
    CheckProgress();
  }, [selectedRow, fieldsForProgress]);

  const workCenterData = (
      item: WorkCenter,
      _principal: Employee | undefined,
      _principalPosition: string | undefined
  ): ReactNode => {
    return (
      <Descriptions size='small' title='Plantel' {...DescritpionProps}>
        <Descriptions.Item key='subsystem' label='Subsistema / IES'>
          {item.subsystem ?? 'No hay Registro'}
        </Descriptions.Item>
        <Descriptions.Item key='workName' label='Plantel'>
          {item.nameShort}
        </Descriptions.Item>
        <Descriptions.Item key='municipality' label='Municipio'>
          {typeof item.municipality === 'string' ? item.municipality : ''}
        </Descriptions.Item>
        <Descriptions.Item
          key='director'
          label='Director(a) de Plantel Firmante'
        >
          <strong>{selectedRow.principal?.name ?? ''}</strong>
          <i> &nbsp; {selectedRow.principalPosition ?? ''}</i>
        </Descriptions.Item>
        <Descriptions.Item
          key='directorSubsystem'
          label='Director(a) general del subsistema / IES'
        >
          {item.currentSubsystemPrincipal ?? ''}
        </Descriptions.Item>
        <Descriptions.Item key='educativeLevel' label='Nivel educativo'>
          {item.educativeLevel ?? ''}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const filesColabAgreement = [{
    title: 'Convenio',
    id: selectedRow.colaborationAgreementFileId
  },{
    title: 'Acta constitutiva de la empresa',
    id: selectedRow.companyId?.incFileId
  },{
    title: 'Poder del representante legal',
    id: selectedRow.legalRepresentativeId?.powerFileId
  },{
    title: 'Idetificación del representante legal',
    id: selectedRow.legalRepresentativeId?.idFileId
  },{
    title: 'Constancia de situación fiscal',
    id: selectedRow.companyId?.fiscalFileId
  }]

  const filesLearningAgreement = [{
    title: 'Convenio',
    id: selectedRow.learningAgreementFileId
  },{
    title: 'Carta de autorización del padre o tutor',
    id: selectedRow.tutorAuthLetterFileId,
    style: letterDisplay,
  },{
    title: INETutorEstudiante,
    id: selectedRow.tutorIdFileId
  },{
    title: 'Seguro facultativo',
    id: selectedRow.insuranceFileId
  },{
    title: 'Plan de Rotación',
    id: selectedRow.rotationPlanFileId
  },{
    title: 'Puestos de Aprendizaje',
    id: selectedRow.learningPositionsFieldId
  },{
    title: 'Matriz de correspondencia',
    id: selectedRow.matrixFieldId
  },{
    title: 'Nombramiento del director',
    id: selectedRow.principalFileId
  },{
    title: 'Responsiva de entrega de laptop',
    id: selectedRow.laptopAssignmentFileId
  },]

  const fileList = (data: FileListItem[]) => (
    <List
      bordered
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={data}
      renderItem={(item: FileListItem) => (
        <List.Item style={{ display: item.style ?? '' }}>
          {item.title && item.id ? (
            <Button
              type='link'
              onClick={() => {
                setPdfViewerId(item.id!);
                setShowDocument(true);
              }}
              icon={
                <FontAwesomeIcon
                  icon={solid('file-pdf')}
                  style={{ color: '#ce0e2c', marginRight: '5px' }}
                />
              }
            >
              {item.title}
            </Button>
          ) : (
            `No hay ${item.title}`
          )}
        </List.Item>
      )}
    />
  );
  return (
    <>
      <Tooltip
        title={percent === 100 ? 'Campos completos' : 'Campos incompletos'}
      >
        <Progress percent={percent} />
      </Tooltip>
      {(typeof selectedRow.minimumRequiredFieldsPercent === 'undefined' ||
        selectedRow.minimumRequiredFieldsPercent < 100) && (
        <>
          <Collapse
            items={[
              {
                key: '1',
                label: 'Ver campos faltantes',
                children: (
                  <RequiredFieldsCheckList
                    isAdult={IsAdultStudent(selectedRow.studentId) || false}
                    agreement={selectedRow}
                  />
                ),
              },
            ]}
            defaultActiveKey={['0']}
            style={{ border: '1px solid #ff4d4f', color: '#ff4d4f' }}
            accordion={true}
            bordered={false}
          />
          <br />
        </>
      )}
      <Descriptions size='small' {...DescritpionProps}>
        <Descriptions.Item key={'expendiete'} label='Expediente'>
          {selectedRow.sequence}
        </Descriptions.Item>
        <Descriptions.Item key='status' label='Estado'>
          <p style={colorStatus(selectedRow.status)}>{selectedRow.status}</p>
        </Descriptions.Item>
        <Descriptions.Item key={'approvalStep'} label='A revisar por' span={4}>
          <Row align='middle' justify='space-evenly'>
            {selectedRow.status === 'BORRADOR' ||
            selectedRow.status === 'RECHAZADO'
              ? 'Vinculadores autorizados'
              : selectedRow.status === 'ACTIVO'
              ? 'Sin revisión pendiente'
              : selectedRow.approvalStep?.name}
            <Button type='primary' onClick={handleOpenModal}>
              Detalles
            </Button>
          </Row>
          <Modal
            title={<h2>Proceso de revisión</h2>}
            open={isModalOpen}
            onCancel={handleOpenModal}
            width={350}
            footer={
              <Row align={'middle'} gutter={5} justify={'space-between'}>
                <Col>
                  <Row>Tu usuario pertenece a: </Row>
                  <Row>{user?.approvementRole?.name ?? 'Vinculadores autorizados'}</Row>
                </Col>
                <Col>
                  <Button type='primary' onClick={handleOpenModal}>
                    OK
                  </Button>
                </Col>
              </Row>
            }
          >
            {approvalLabels()}
            <Divider style={{ margin: '5px 0px 20px 0px' }} />
          </Modal>
        </Descriptions.Item>
        <Descriptions.Item
          key='datesInitEnd'
          label='Fecha Inicio y Finalizacion'
        >
          {valueDate(selectedRow.startDate)} - {valueDate(selectedRow.endDate)}
        </Descriptions.Item>
        <Descriptions.Item key='hoursPerWeek' label='Horas a la semana'>
          <p>{selectedRow.hoursPerWeek ?? 'Sin registro'}</p>
        </Descriptions.Item>
        {selectedRow.status === 'INACTIVO' ? (
          <>
            <Descriptions.Item key='inactiveDate' label='Fecha de baja'>
              {selectedRow.inactiveDate
                ? valueDate(selectedRow.inactiveDate)
                : ''}
            </Descriptions.Item>
            <Descriptions.Item key='completedHours' label='Horas completadas'>
              <p style={colorCompletedHours(selectedRow.completedHours ?? 0)}>
                {selectedRow.completedHours
                  ? getHoursMinutesString(selectedRow.completedHours)
                  : 'Sin registro'}
                <InfoCircle info='Número de semanas completas entre la fechas de inicio y de baja, multiplicado por horas a la semana' />
              </p>
            </Descriptions.Item>
            <Descriptions.Item
              key='inactiveLetterFileId'
              label='Carta de baja de expediente'
            >
              {selectedRow.inactiveLetterFileId !== undefined ? (
                <Button
                  type='link'
                  onClick={() => {
                    setPdfViewerId(selectedRow.inactiveLetterFileId!);
                    setShowDocument(true);
                  }}
                  icon={
                    <FontAwesomeIcon
                      icon={solid('file-pdf')}
                      style={{ color: '#ce0e2c', marginRight: '5px' }}
                    />
                  }
                >
                  {' '}
                  {'Carta de baja de expediente'}{' '}
                </Button>
              ) : (
                `No hay Carta de baja de expediente`
              )}
            </Descriptions.Item>
          </>
        ) : (
          showTodayEstimate && (
            <Descriptions.Item key='hoursCounter' label='Horas actuales'>
              <p style={colorCompletedHours(hoursEstimate)}>
                {hoursEstimate ?? 'Sin registro'}
                <InfoCircle info='Número de semanas completas entre la fechas de inicio y el día de hoy, multiplicado por horas a la semana' />
              </p>
            </Descriptions.Item>
          )
        )}
        {showProjectionEstimate && (
          <Descriptions.Item key='hoursProyection' label='Proyección de horas'>
            <p style={colorCompletedHours(hoursProjection)}>
              {hoursProjection ?? 'Sin registro'}
              <InfoCircle info='Número de semanas completas entre las fechas de inicio y finalización, multiplicado por horas a la semana' />
            </p>
          </Descriptions.Item>
        )}
      </Descriptions>
      <Divider orientation='left'>
        Convenio de Colaboración y archivos de la empresa
      </Divider>
      {selectedRow.companyId
        ? fileList(filesColabAgreement)
        : 'No hay empresa seleccionada'}
      <Divider orientation='left'>Convenio de Aprendizaje</Divider>
      {fileList(filesLearningAgreement)}
      <Row>
        <Col>
          <div style={{ padding: '15px' }}>
            <Text strong>
              Número de puestos en los que rotará el estudiante
            </Text>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Text>{selectedRow.rotationTimes}</Text>
          </div>
        </Col>
        <Col>
          <div style={{ padding: '15px' }}>
            <Text strong>Puestos en los que rotará el estudiante</Text>
          </div>
          <div style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            <Text>{selectedRow.rotationPositions}</Text>
          </div>
        </Col>
      </Row>
      <br />
      {visibleLaptopFields && (
        <Row>
          <Col>
            <div style={{ padding: '15px' }}>
              <Text strong>Número de serie de laptop</Text>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Text>{selectedRow.laptopSN ?? 'No ingresado'}</Text>
            </div>
          </Col>
          <Col>
            <div style={{ padding: '15px' }}>
              <Text strong>Fecha de entrega de laptop</Text>
            </div>
            <div style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
              <Text>
                {selectedRow.laptopDeliveryDate
                  ? valueDate(selectedRow.laptopDeliveryDate)
                  : 'No ingresada'}
              </Text>
            </div>
          </Col>
        </Row>
      )}
      <br />
      <Document
        fileId={pdfViewerId}
        open={showDocument}
        onCancel={() => setShowDocument(false)}
        onOk={() => setShowDocument(false)}
      />
      {selectedRow.studentId && (
        <AgreementStudent detailStudent={selectedRow.studentId} />
      )}
      <br />
      {selectedRow.workCenterId
        ? workCenterData(
            {
              ...selectedRow.workCenterId,
              educativeLevel: selectedRow.educativeLevel,
              municipality: selectedRow.municipality,
            },
            selectedRow.principal,
            selectedRow.principalPosition
          )
        : 'Contacta al Administrador'}
      <br />
      {selectedRow.companyId && (
        <CompnayAgremnt
          detailCompany={selectedRow.companyId}
          representative={selectedRow.representativeId}
          legalRep={selectedRow.legalRepresentativeId}
        />
      )}
      <br />
      <Descriptions
        bordered
        size='small'
        title='Comentarios'
        extra={
          <CommentsOptions
            selectedRow={selectedRow}
            closeModal={closeModalDetails as (arg0: boolean) => void}
            visibleRegresar={false}
          />
        }
      ></Descriptions>
      {selectedRow.comments && <ComentsAgreement selectedRow={selectedRow} />}
      <Divider />
    </>
  );
};