import React, { useState } from "react";
import * as S from "./Company.styles";
import { Representative, reptype, typeRepresentative, typeRelation } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { Drawer, Form, Input, Select } from "antd";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Document } from "components/common/Document/Document";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAppDispatch } from "hooks/reduxHooks";
import { doUpdate } from "store/slices/representativeSlice";
import { notificationController } from "controllers/notificationController";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";

interface RepsEditProps {
  rep: Representative;
  onClose?: () => void;
  open: boolean;
  type?: typeof typeRepresentative[number];
}

export const RepsEdit = ({ rep, onClose, open, type="Contacto"}: RepsEditProps) => {
  const dispatch = useAppDispatch();
  
  const [ form ] = Form.useForm();
  const powerFileIdHook = Form.useWatch('powerFileId', form);
  const idFileIdHook = Form.useWatch('idFileId', form);

  const [ showDocument, setShowDocument ] = useState(false);
  const [ pdfViewerId, setPdfViewerId ] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleSubmit = async (values: Representative) => {
    setUpdating(true);
    const changedValues = form.getFieldsValue(true, meta => meta.touched);
    const sendPayload = {
      changedValues,
      representative: values
    }
    await dispatch(doUpdate(sendPayload))
    .unwrap()
    .then((_repSaved) => {
      notificationController.success({
        message: `${typeRelation[type]} actualizado exitosamente`,
      });
      if(onClose) {
        onClose()
      }
    })
    .catch((_err) => {
      notificationController.error({
        message: `Error al actualizar ${typeRelation[type]}`,
      });
    });
    setUpdating(false)
  };

  return (
    <Drawer
      title={`Editar ${typeRelation[type]}`}
      placement='bottom'
      open={open}
      onClose={onClose}
    >
      <BaseForm
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark='optional'
        initialValues={rep}
      >
        <BaseForm.Item name="_id" hidden={true} />
        <BaseForm.Item name="companyId" hidden={true} />
        <BaseForm.Item
          name='name'
          key='name'
          label={`Nombre(s)`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el nombre' />
        </BaseForm.Item>
        <BaseForm.Item
          name='phone'
          key='phone'
          label={`Teléfono`}
        >
          <Input placeholder='Ingrese el telefono de contacto' />
        </BaseForm.Item>
        <BaseForm.Item
          name='email'
          key='email'
          label={`Dirección de Correo Electrónico`}
          rules={[
            {
              type: "email",
              message: "Por favor, ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input placeholder='Ingrese el email de contacto' />
        </BaseForm.Item>
        <BaseForm.Item
          name="type"
          label={`Contacto o representante`}
          rules={[
            { required: true, message: "Este campo es obligatorio" },
          ]}
        >
          <Select
            style={{ width: 150 }}
            options={reptype.map((typeOfRep) => ({
              label: typeOfRep,
              value:typeRelation[typeOfRep],
              key: typeRelation[typeOfRep],
            }))}
          />
        </BaseForm.Item>
        <BaseForm.Item
          name='position'
          key='position'
          label={`Puesto`}
        >
          <Input placeholder='Ingrese el puesto' />
        </BaseForm.Item>
        <BaseForm.Item
          label={`Identificación Oficial`}
          name='idFileId'
        >
          { rep.idFileId && (
            <ShowDocumentBtn
            label='ver documento original'
            onClick={() => {
              setPdfViewerId(rep.idFileId as string);
              setShowDocument(true);
            }}
          />
          )}
          { idFileIdHook !== rep.idFileId && (
            <>
              <FontAwesomeIcon
                icon={solid("circle-arrow-right")}
                style={{ color: "#ce0e2c", marginRight: '5px' }}
              />
              <ShowDocumentBtn
                label="ver nuevo documento"
                onClick={() => {
                  setPdfViewerId(idFileIdHook);
                  setShowDocument(true);
                }}
              />
            </>
          ) }
          <AWSFileUploader
            title="Identificación Oficial"
            bucket="representative-id"
            isReplacing={form.getFieldValue("idFileId")}
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("idFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item
          label={`Poder Notarial`}
          name='powerFileId'
        >
          { rep.powerFileId && (
            <ShowDocumentBtn
            label='ver documento original'
            onClick={() => {
              setPdfViewerId(rep.powerFileId as string);
              setShowDocument(true);
            }}
          />
          )}
          { powerFileIdHook !== rep.powerFileId && (
            <>
              <FontAwesomeIcon
                icon={solid("circle-arrow-right")}
                style={{ color: "#ce0e2c", marginRight: '5px' }}
              />
              <ShowDocumentBtn
                label="ver nuevo documento"
                onClick={() => {
                  setPdfViewerId(powerFileIdHook);
                  setShowDocument(true);
                }}
              />
            </>
          ) }
          <AWSFileUploader
            title="Poder Notarial"
            bucket="representative-power"
            isReplacing={form.getFieldValue("powerFileId")}
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("powerFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton type='primary' htmlType='submit' size='large' disabled={updating}>
            {`Actualizar`}
          </S.SubmitButton>
        </BaseForm.Item>
        <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => setShowDocument(false)}
            onOk={() => setShowDocument(false)}
          />
      </BaseForm>
    </Drawer>
  );
};
