import { PageTitle } from "components/common/PageTitle/PageTitle";
import { SubsystemNewForm } from "components/subsystems/SubsystemNewForm";
import React from "react";

const SubsystemNewPage: React.FC = () => {
  return (
    <>
        <PageTitle>{`Nuevo subsistema`}</PageTitle>
        <SubsystemNewForm />
    </>
  );
};

export default SubsystemNewPage;
