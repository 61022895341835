import React, { useState, useEffect } from "react";
import * as S from "../Student.styles";
import { useNavigate } from "react-router-dom";
import {
  Student,
  academicStatus,
  geneders,
  gradesString,
  initialValues,
  patternCurp,
} from "../types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Col, DatePicker, Input, Row, Select, Form } from "antd";
import { doRegister } from "store/slices/studentSlice";
import { notificationController } from "controllers/notificationController";
import { doList as WorkcenterSub } from "store/slices/workCenterSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";

import { WorkCenter } from "../../workcenters/types";
import { OptionsSelect } from "types/optionsSelect";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { escapeRegExp } from "lodash";
import { IsAdultStudentCurp } from "components/Agreements/utils/utils";

export const StudentNew: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  // Data for select
  const [valueSubSystem, setValueSubSystem] = useState("");
  const [options, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<WorkCenter[]>([]);

   //check if adult
   const [isAdult, setIsAdult] = useState(false);
   const curpHook = Form.useWatch('curp', form);

   useEffect(() => {
    if(curpHook && curpHook!=='' && IsAdultStudentCurp(curpHook)) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  }, [curpHook])

  useEffect(() => {
    if (workcenterStateStatus !== "fulfilled") {
      dispatch(WorkcenterSub());
    }
  }, [dispatch, workcenterStateStatus]);
  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);
  useEffect(() => {
    const optionsRaw: OptionsSelect[] = workcenters.map((workCeter) => ({
      label: workCeter.name!,
      value: workCeter._id!,
      key: workCeter._id!,
    }));
    setOptionWorkCenter(optionsRaw);
  }, [workcenters]);

  const filterWorkcenter = (workCenterName: string) => {
    const escapedWorkcName = escapeRegExp(workCenterName);
    const inputString = new RegExp(`${escapedWorkcName}`, "gi");
    const newOptions = workcenters
      .filter((value) => inputString.test(value.name))
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(newOptions);
  };

  const changeSubsystem = (subsystemSelected: string) => {
    const optionsRaw: OptionsSelect[] = workcenters
      .filter((workcenter) => workcenter.subsystemShort === subsystemSelected)
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(optionsRaw);
    setValueSubSystem(subsystemSelected);
  };

  const handleSubmit = (values: Student) => {
    setLoading(true);
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Alumno registrado con éxito',
        });
        navigate('/alumnos');
      })
      .catch((err) => {
        if (err.message && err.message.includes('status code 427')) {
          notificationController.error({
            message: 'Error: Curp o correo duplicado',
            description:
              'Puede que el alumno ya se encuentre registrado en otro plantel, solicita a un administrador el cambio de plantel del estudiante.',
          });
        } else {
          notificationController.error({
            message: 'Error al registrar alumno',
          });
        }
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.Card
        id='students-new'
        title={
          <>
            <CloseButton onClick={()=>{navigate(STUDENTS_PATH)}}></CloseButton>
            <span>&nbsp; {`Nuevo Alumno`}</span>
          </>
        }
        padding='1.25rem'>
          <BaseForm
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark='optional'
            initialValues={initialValues}
            form={form}
          >
             <Row>
              <Col style={{ marginRight: 25 }}>
                <BaseForm.Item required={false} label={`Subsistema / IES`} name="subsystem">
                  <Select
                    style={{ width: 200 }}
                    value={valueSubSystem}
                    onChange={changeSubsystem}
                    placeholder='Sub-Sistema'
                    options={optionsSubsystem}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name='workcenter'
                  label={`Plantel`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    showSearch
                    value={value}
                    onSearch={filterWorkcenter}
                    onChange={(newValue) => {
                      setValue(newValue as WorkCenter[]);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    options={options}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='name'
                  label={`Nombre(s)`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder='Ingrese el nombre' />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='paternalLastname'
                  label={`Apellido paterno`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder='Ingrese el apellido' />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='maternalLastname'
                  label={`Apellido materno`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder='Ingrese el apellido' />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='email'
                  label={`Correo Electrónico`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                    {
                      type: "email",
                      message: "Por favor, ingresa un correo electrónico válido",
                    },
                  ]}
                >
                  <Input placeholder='Correo Electrónico' />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='curp'
                  label={`CURP`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                    {
                      validator: (rule, value) => {
                        if (value && patternCurp.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Ingresa una CURP válida usando mayúsculas y números');
                      },
                    },
                  ]}
                >
                  <Input placeholder='A-Z' showCount maxLength={18} />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='phone'
                  label={`Teléfono`}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Input />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item 
                  name='gender' 
                  label={`Genero`}
                  rules={[
                    {
                      required: true,
                      message:'Este campo es obligatorio',
                    },
                  ]}>
                  <Select
                    style={{ width: 200 }}
                    options={geneders.map((options) => ({
                      label: options,
                      value: options,
                      key: options,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='career'
                  label={`Carrera`}
                  rules={[
                          {
                            required: true,
                            message:'Este campo es obligatorio',
                          },
                        ]}>
                  <Input placeholder='Escribe tu Carrera' max={60} />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='grade'
                  label={`Periodo escolar`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    options={gradesString.map((grade) => ({
                      label: grade,
                      value: gradesString.indexOf(grade),
                      key: grade,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name='birthDate'
                  label={"Fecha de nacimiento"}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <DatePicker />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
              <BaseForm.Item
                  name="academicStatus"
                  label={`Estado Académico`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: 200 }}
                    options={academicStatus.map((status) => ({
                      label: status,
                      value: status,
                      key: status,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='tutor'
                  label={`Nombre(s) y Apellidos del Tutor`}
                >
                  <Input placeholder='Ingrese el nombre' />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name='completionDate'
                  label={"Fecha de Graduación"}
                >
                  <DatePicker bordered />
                </BaseForm.Item>
              </Col>
            </Row>

            <BaseForm.Item noStyle>
              <S.SubmitButton
                type='primary'
                htmlType='submit'
                size='large'
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </S.Card>
      </S.Wrapper>
    </>
  );
};
