import { Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ColumnsType } from 'antd/es/table';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type DownloadTableButtonT<T> = {
  columns: ColumnsType<T>;
  data?: object[];
  size?: SizeType;
  type?: 'link' | 'text' | 'default' | 'primary' | 'dashed';
  label?: string;
};

export const DownloadTableButton = <T extends object>({
  columns,
  data,
  size,
  type,
  label,
}: DownloadTableButtonT<T>) => {
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>();
  useEffect(() => {
    if (columns && data) {
      const headers = columns.map((column) => ({
        label: column.title ? column.title.toString() : '',
        key: column.key ? column.key.toString() : '',
      }));
      setHeaders(headers);
    }
  }, [columns, data]);

  if (!data) return <></>;

  return (
    <Button size={size ?? 'small'} type={type ?? 'default'}>
      <CSVLink headers={headers} data={data}>
        <Space>
          {label}
          <DownloadOutlined />
        </Space>
      </CSVLink>
    </Button>
  );
};
